/* eslint-disable */
import { isFunction } from 'lodash'
import { vuex as Inquiries } from './inquiries'
import { vuex as Orders } from './orders'
import { vuex as Customers } from './customers'
import { vuex as Providers } from './providers'
import { vuex as MaterialOrders } from './materialorders'
import { vuex as ExternalCooperations } from './externalcooperations'
import { vuex as Components } from './components'
import { vuex as WorkCenters } from './workcenters'
import { vuex as Reports } from './reports'
import { vuex as OrderControl } from './ordercontrol'
import { vuex as ShopFloorControl } from './shopfloorcontrol'
import { vuex as Settings } from './settings'
import { vuex as Auth } from './auth'
import { vuex as Version } from './version'
import { vuex as Resources } from './resources'
import { vuex as CorporateSettings } from './corporatesettings'
import { vuex as Users } from './users'
import { vuex as Roles } from './roles'
import { vuex as Alerts } from './alerts'
import { vuex as CustomerOrders } from './customerorders'
import { vuex as Schedule } from './schedule'
import { vuex as MobileApp } from './mobileapp'

// start extraction data from vuex modules
const vuex = {
  ShopFloorControl,
  Inquiries,
  Orders,
  Customers,
  Providers,
  MaterialOrders,
  ExternalCooperations,
  Components,
  WorkCenters,
  OrderControl,
  Auth,
  Settings,
  Version,
  Resources,
  Reports,
  CorporateSettings,
  Users,
  Roles,
  Alerts,
  CustomerOrders,
  Schedule,
  MobileApp,
}

const keys = Object.keys(vuex)
// process and extract data (modules and plugins)
/**
 * this is a full functional approach
 * this code use reduce end immutability with spread operator to generate new object and array
 * refs
 * - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/Reduce
 * - https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Operators/Spread_operator
 * - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/Arrow_functions
 *
 * Immutability is very important concept from functional programming, that's prevents side effects
 * Together with the syntax of arrow function make the code more concise
 *
 * plugins have additional treatment, with `.filter`, because not every module has plugins
 */
const modules = keys.reduce(
  (acc, key) => ({ ...acc, [key]: vuex[key].module }),
  {}
)
const plugins = keys
  .reduce((acc, key) => [...acc, vuex[key].plugin], [])
  .filter(isFunction)
/**
 * semi-functional version
 * const modules = keys.reduce((acc, key) => {
 *   acc[key] = vuex[key].module
 *   return acc // without immutability
 *   return { ...acc } // with immutability
 * }, {})
 *
 * const plugins = keys.reduce((acc, key) => {
 *   acc.push(vuex[key].plugins)
 *   return acc // without immutability
 *   return [...acc] // with immutability
 * }).filter(plugin => isFunction(plugin))
 */
// end of extraction

// Shorthand for ‘modules: modules’
export default { modules, plugins }
