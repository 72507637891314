import * as TYPES from '../../store/types'

const state = {
  materialOrders: [],
  materialOrders_metadata: {},
  readOnly: false,
}

/* eslint-disable no-param-reassign */
const mutations = {
  [TYPES.MATERIALORDERS_SET_DATA](st, obj) {
    if (obj.materialOrders) {
      st.materialOrders = obj.materialOrders
    }

    if (obj.metadata) {
      st.materialOrders_metadata = obj.metadata
    }

    st.readOnly = !!obj.readOnly
  },
}

const actions = {
  materialOrdersSetData({ commit }, obj) {
    commit(TYPES.MATERIALORDERS_SET_DATA, obj)
  },
}

const module = {
  state,
  mutations,
  actions,
}

export default { module }
