const CustomerOrders = () => import('./main.vue')

const meta = {
  requiresAuth: false,
}

export default [
  {
    name: 'customerorders.index',
    path: '/customers/orders/view/:tenantId/:key',
    component: CustomerOrders,
    meta,
  },
]
