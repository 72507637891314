import * as TYPES from '../../store/types'

const state = {
  components: [],
  components_metadata: {},
  components_totalCount: 0,
  readOnly: false,
}

/* eslint-disable no-param-reassign */
const mutations = {
  [TYPES.COMPONENTS_SET_DATA](st, obj) {
    if (obj.components) {
      st.components = obj.components
    }

    if (obj.metadata) {
      st.components_metadata = obj.metadata
    }

    if (obj.totalCount) {
      st.components_totalCount = obj.totalCount
    }

    st.readOnly = !!obj.readOnly
  },
}

const actions = {
  componentsSetData({ commit }, obj) {
    commit(TYPES.COMPONENTS_SET_DATA, obj)
  },
}

const module = {
  state,
  mutations,
  actions,
}

export default { module }
