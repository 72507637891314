const Roles = () => import('./main.vue')
const Form = () => import('./form.vue')

const meta = {
  requiresAuth: true,
}

const metaNoAuth = {
  requiresAuth: false,
}

export default [
  {
    name: 'roles.index',
    path: '/roles',
    component: Roles,
    meta,
    children: [
      {
        name: 'roles.new',
        path: 'create',
        component: Form,
        meta,
      },
      {
        name: 'roles.edit',
        path: ':id',
        component: Form,
        meta,
      },
    ],
  },
  {
        name: 'roles.new',
        path: '/roles/create',
        component: Form,
        meta,
      },
      {
        name: 'roles.edit',
        path: '/roles/:id',
        component: Form,
        meta,
      }
]
