<template>
  <div v-if="shouldHide(entity)">
  </div>

  <div v-else-if="isType('label') || isType('readonlyDurationInUserUnits')">
    <span :class="getLabelClass()" :title="getLabelValue()">{{
      getLabelValue()
    }}</span>
  </div>

  <div v-else-if="isType('dateLabel')">
    <span
      :class="getLabelClass()"
      :title="getFormattedDate(entity[property])"
      >{{ getFormattedDate(entity[property]) }}</span
    >
  </div>

  <div v-else-if="isType('dateTimeLabel')">
      <span
        :class="getLabelClass()"
        :title="getFormattedDateTime(entity[property])"
        >{{ getFormattedDateTime(entity[property]) }}</span
      >
    </div>

  <div v-else-if="isType('statusLabel')">
    <el-tooltip v-if="hasHint()" placement="top" :effect="getTooltipEffect">
      <template v-slot:content>
        <div v-html="getHint()"></div>
      </template>

      <!-- todo - have to implement status for this -->

      <div
        :class="'row-index ' + getStatusClass(entity, property)"
        style="min-height: 16px"
      >
        <span class="inactive-action-idx">{{ entity.__prefix }}</span>
        <a
          v-if="hasStatusLinkFormula()"
          href="javascript:void(0);"
          v-on:click="openLink(entity)"
          >{{ entity[property] }}</a
        >
        <span v-if="!hasStatusLinkFormula()">{{ entity[property] }}</span>
        <span class="inactive-action-idx">{{ entity.__suffix }}</span>
      </div>
    </el-tooltip>

    <div
      v-else
      :class="'row-index ' + getStatusClass(entity, property)"
    >
      {{ entity[property] }}
    </div>
  </div>

  <div v-else-if="isType('statusDateLabel')">
    <el-tooltip v-if="hasHint()" placement="top" :effect="getTooltipEffect">
      <template v-slot:content>
        <div v-html="getHint()"></div>
      </template>

      <!-- todo - have to implement status for this -->

      <div :class="'row-index ' + getStatusClass(entity, property)">
        <span v-if="!hasStatusLinkFormula()">{{
          getFormattedDate(entity[property])
        }}</span>
      </div>
    </el-tooltip>

    <div
      v-if="!hasHint()"
      :class="'row-index ' + getStatusClass(entity, property)"
    >
      {{ getFormattedDate(entity[property]) }}
    </div>
  </div>

  <div v-else-if="isType('input')">
    <el-input v-model="entity[property]" ></el-input>
  </div>

  <div v-else-if="isType('textArea')">
    <el-input v-model="entity[property]" type="textarea"></el-input>
  </div>

  <div v-else-if="isType('numeric')" >
    <el-input-number
      v-model="entity[property]"
      controls-position="right"
      @change="selectedValueChanged"
      :style="getStyle"
    ></el-input-number>
  </div>

  <div v-else-if="isType('decimal')">
    <el-input-number
      v-model="entity[property]"
      type="number"
      :step="getPrecision"
      controls-position="right"
      :class="additionalClass"
    ></el-input-number>
  </div>
  
   <div v-else-if="isType('durationInUserUnits')">
        <el-input-number
          v-model="durationInUserUnits"
          type="number"
          :step="getPrecision"
          controls-position="right"
          :class="additionalClass"
          @blur="selectedValueChanged"
        ></el-input-number>
    </div>

  <div v-else-if="isType('pricePerDurationUnits')">
      <el-input-number
        v-model="priceInUserUnits"
        type="number"
        :step="getPrecision"
        controls-position="right"
        :class="additionalClass"
        
      ></el-input-number>
  </div>

  <div v-else-if="isType('date')">
    <el-date-picker
      v-model="entity[property]"
      type="date"
      placeholder="Pick a day"
      :format="getDateFormat"
    >
    </el-date-picker>
  </div>

  <div v-else-if="isType('dateTime')">
      <el-date-picker
        v-model="entity[property]"
        type="datetime"
        placeholder="Select date and time"
        :format="getDateTimeFormat"
      >
      </el-date-picker>
    </div>

  <div v-else-if="isType('checkbox')">
    <el-checkbox v-model="entity[property]"></el-checkbox>
  </div>

  <div v-else-if="isType('readonlyCheckbox')">
    <el-checkbox v-model="entity[property]" disabled></el-checkbox>
  </div>

  <div v-else-if="isType('select')" >
    <el-select v-model="entity[property]" @change="selectedValueChanged" filterable >
      <el-option
        v-for="option in getSelectOptions"
        v-bind:value="option.id"
        :label="option.text"
        :key="option.id"
        >{{ option.text }}</el-option
      >
    </el-select>
  </div>

  <div v-else-if="isType('multiSelect')">
    <el-select
      v-model="entity[property]"
      multiple
      filterable
      placeholder="Select"
      @change="selectedValueChanged"
      style="min-width: 360px"
    >
      <el-option
        v-for="option in getSelectOptions"
        :value="option.id"
        :label="option.text"
        :key="option.id"
      >
      </el-option>
    </el-select>
  </div>

  <div v-else-if="isType('calculated')">
    <span
      :class="getLabelClass()"
      :title="getCalculatedValueHint(entity, property)"
      >{{ evaluateFormulaAndAddSuffix(entity, property) }}</span
    >
  </div>

  <div v-else-if="isType('calculatedHidden')" style="display: none">
    <span :class="getLabelClass()">{{
      evaluateFormulaAndAddSuffix(entity, property)
    }}</span>
  </div>

  <div v-else-if="isType('varList')">
    <cc-inline-var-list
      :rootEntity="rootEntity"
      :items="entity[property]"
      :metadata="getVarListMetadata" 
      :readOnly="readOnly"
      @linkMethodCallback="emitLinkMethodCallback"
       />
  </div>

  <div v-else-if="isType('empty')" :class="hasHint() ? 'fill-100-percent' : ''">
    <el-tooltip
      v-if="hasHint()"
      placement="top-start"
      :effect="getTooltipEffect"
    >
    <template v-slot:content>
      <div v-html="getHint()"></div>
    </template>

      <div :class="'row-index '" class="fill-100-percent">
        <!--<span style="margin-left: -20px">!</span>-->
      </div>
    </el-tooltip>
  </div>

  <div v-else-if="isType('header')"></div>

  <div v-else-if="isType('link')">
    <el-tooltip
      v-if="hasHint()"
      placement="top-start"
      :effect="getTooltipEffect"
    >
    <template v-slot:content>
      <div v-html="getHint()"></div>
    </template>
      <a href="javascript:void(0);" v-on:click="openLink(entity)">{{ entity[property] }}</a>
    </el-tooltip>

    <div v-if="!hasHint()">
      <a href="javascript:void(0);" v-on:click="openLink(entity)" :title="entity[property]">{{ entity[property] }}</a>
    </div>
  </div>

  <div v-else-if="isType('linkArray')">
    <div v-for="itm in entity[property]" :key="itm.id" class="linkarray">
      <a
        href="javascript:void(0);"
        v-on:click="openLink(itm)"
        class="linkarray"
        >{{ getLinkArrayItemText(itm) }}</a
      >
    </div>
  </div>

  <div v-else-if="isType('inputArray')">
    <cc-input-array
      type="input"
      :metadata="metadata"
      :entity="entity"
      :property="property"
      :readOnly="readOnly && !this.metadata.allowEditReadonly"
    />
  </div>

  <div v-else-if="isType('inputLinksArray')">
    <cc-input-array
      type="inputLinks"
      :metadata="metadata"
      :entity="entity"
      :property="property"
      :readOnly="readOnly && !this.metadata.allowEditReadonly"
    />
  </div>

  <div v-else-if="isType('emailArray')">
    <cc-input-array
      type="email"
      :metadata="metadata"
      :entity="entity"
      :property="property"
      :readOnly="readOnly"
    />
  </div>

  <div v-else-if="isType('readonlyArray')">
    <cc-input-array
      type="email"
      :metadata="metadata"
      :entity="entity"
      :property="property"
      :readOnly="true"
    />
  </div>

  <div v-else-if="isType('filesArray')">
    <cc-files-list
      :files="entity[property]"
      :propName="property"
    ></cc-files-list>
  </div>

  <div v-else-if="isType('readonlyFilesArray')">
    <cc-files-list
      :files="entity[property]"
      :propName="property"
      :readonly="true"
      :formula="metadata.formula"
      :entity="entity"
    ></cc-files-list>
  </div>

  <div v-else-if="isType('file')">
    <cc-file-ctrl :file="entity[property]" />
  </div>

  <div v-else-if="isType('lookup')" style="display: flex;">
    <el-autocomplete
      size="default"
      v-model="entity[property]"
      :fetch-suggestions="querySearchAsync"
      placeholder="type name"
      @blur="onAutoCompleteBlur"
      @select="fillAutoCompleteObjectData"
      width = "400px"
    ></el-autocomplete>
    <el-link
      v-if="metadata.hasReloadButton && !readOnly"
      :icon="Refresh"
      style="font-size: xx-large; margin-left: 8px;"
      :underline="false"
      @click="onRefreshComponent"
    ></el-link>
  </div>

  <div v-else-if="isType('complex')">
    <div v-for="(itm, idx) in getComplexItems()" :key="idx" class="complex-item">
      <a v-if="itm.type == 'link'" href="javascript:void(0);" v-on:click="openLink(itm)" >{{ itm.text }}</a>
      <span v-else-if="itm.type == 'label'"> {{ itm.text ? itm.text : entity[itm.id] }} </span>
      <span v-else-if="itm.type == 'dateLabel'" :class="getLabelClass()" :title="getFormattedDate(entity[itm.id])">{{ getFormattedDate(entity[itm.id]) }}</span>
      <el-input v-else-if="itm.type == 'input'" v-model="entity[itm.id]"></el-input>
      <el-input-number v-else-if="itm.type == 'numeric'" v-model="entity[itm.id]" controls-position="right" :style="getStyle" ></el-input-number>
      <el-date-picker v-else-if="itm.type == 'date'" v-model="entity[itm.id]" type="date" placeholder="Pick a day" :format="getDateFormat" />
      <div v-else-if="itm.type == 'statusLabel'"  :class="'row-index ' + getStatusClass(entity, itm.id)" > {{ entity[itm.id] }} </div>
      <span v-else-if="itm.type == 'calculated'" :class="getLabelClass()" >{{ evaluateComplexItemFormula(entity, itm.id, itm.formula) }}</span>
    </div>
  </div>

  <div v-else-if="isType('email')">
    <el-input v-model="entity[property]" type="email"></el-input>
  </div>

  <div v-else-if="isType('password')">
    <el-input v-model="entity[property]" type="password"></el-input>
  </div>
</template>

<script>
import { markRaw } from 'vue'
import { Refresh } from '@element-plus/icons-vue'
import { mapActions, mapGetters } from 'vuex'
//import { getBaseUrl as httpGetBaseUrl } from '../../plugins/http'


const AUTOCOMPLETE_MIN_CHARS = 3

const getReadOnlyMetadataType = function(metadataType) {
        switch (metadataType) {
          case 'statusLabel':
          case 'dateLabel':
          case 'dateTimeLabel':
          case 'statusDateLabel':
          case 'calculated':
          case 'calculatedHidden':
          case 'empty':
          case 'header':
          case 'link':
          case 'linkArray':
          case 'varList':
            return metadataType
          case 'date':
            return 'dateLabel'
          case 'dateTime':
            return 'dateTimeLabel'
          case 'password':
            return ''
          case 'checkbox':
            return 'readonlyCheckbox'
          case 'inputArray':
          case 'inputLinksArray':
          case 'emailArray':
          case 'readonlyArray':
            return 'readonlyArray'
          case 'filesArray':
          case 'file':
          case 'readonlyFilesArray':
            return 'readonlyFilesArray'
          case 'complex':
            return 'complex'

          // case 'pricePerDurationUnits':
          case 'durationInUserUnits':
            return 'readonlyDurationInUserUnits'
          default:
            return 'label'
        }
      }

export default {
  data() {
    return {
      Refresh: markRaw(Refresh),
    }
  },
  name: 'CcVarControl',
  components: {},
  props: {
    metadata: null, // {type: 'label', formula: '', options: [], lookupEntityType: 'Customer' }
    entity: {},
    property: '',
    rootEntity: {},
    boldLabels: false,
    additionalClass: '',
    readOnly: false,
    onchanged: undefined,
  },

  /*
  setup(props) {
    if (props.metadata && props.metadata.type) {
      const controlType = props.readOnly && !props.metadata.allowEditReadonly
            ? getReadOnlyMetadataType(props.metadata.type)
            : props.metadata.type
      return {
        controlType,
      }
    } else {
      console.error('unknown control type')
      return {
        controlType: undefined,
      }
    }
  },*/

  computed: {
    ...mapGetters(['globalMetadata']),

    durationInUserUnits: { 
      get() { 
        return this.toDurationInUserUnits(this.entity[this.property])
      },
      set(value) { 
        switch (this.globalMetadata.durationUnits) {
          case "Days":
            this.entity[this.property] = value * 60 * 24
            break
          case "Hours":
            this.entity[this.property] = value * 60
            break
          case "Minutes":
          default:
            this.entity[this.property] = value
            break
        }

        // console.log('minutes: ' + this.entity[this.property] + ' prop: ' + this.property)
      }
    },

    getStyle() {
      if(this.metadata && this.metadata.width && this.metadata.width > 0)
        return `width:${this.metadata.width}px`
      return ''
    },

    priceInUserUnits: {
      get() {
        return this.toUserPricePerDurationUnits(this.entity[this.property])
      },
      set(value) {
        switch (this.globalMetadata.durationUnits) {
          case "Days":
            this.entity[this.property] = value / (60 * 24)
            break
          case "Hours":
            this.entity[this.property] = value / 60
            break
          case "Minutes":
          default:
            this.entity[this.property] = value
            break
        }

        // console.log('price in minutes: ' + this.entity[this.property] + ' prop: ' + this.property)
      }
    },

    getSelectOptions() {
      if (!this.metadata || !this.metadata.options) {
        return []
      }

      return this.metadata.options
    },

    getVarListMetadata() {
      if (this.metadata && this.metadata.metadata) {
        return this.metadata.metadata
      }

      return { columns: [] }
    },

    getTooltipEffect() {
      return document.body.style.getPropertyValue('--tooltip-effect') == 'dark'
        ? 'dark'
        : 'light'
    },

    getDateFormat() {
      if (this.globalMetadata && this.globalMetadata.dateFormat) {
        return this.globalMetadata.dateFormat.toUpperCase()
      }

      return 'yyyy-MM-dd'.toUpperCase()
    },

    getDateTimeFormat() {
      return this.getDateFormat + " HH:mm"
    },

    getPrecision() {
      // console.log(this.globalMetadata.precision)
      if (
        this.globalMetadata &&
        this.globalMetadata.precision &&
        this.globalMetadata.precision > 0.000001
      ) {
        return this.globalMetadata.precision
      }

      return 0.001
    },
  },
  watch: {},
  
  methods: {
    mounted() {},

    isType(type) {
      if (this.metadata && this.metadata.type) {
        var controlType =
          this.readOnly && !this.metadata.allowEditReadonly
            ? getReadOnlyMetadataType(this.metadata.type)
            : this.metadata.type
            
        return controlType == type
      } else {
        console.error('unknown control type')
        return false
      }
    },
    /*
    isType (type){
      return this.controlType === type
    },*/

    hasSuffix() {
      return (
        this.metadata &&
        !!this.metadata.suffix &&
        this.metadata.suffix.length > 0
      )
    },

    getFormula() {
      if (!this.metadata || !this.metadata.formula) {
        return []
      }

      return this.metadata.formula
    },

    shouldHide(item) {
      if(this.metadata && this.metadata.showIfFormula) {
        try {
          var rootEntity = this.rootEntity
          return !eval(this.metadata.showIfFormula)
        } catch (e) {
          console.error('ERROR: evaluateFormula: ' + e.message)
          return false;
        }
      }
        
      return false
    },

    evaluateComplexItemFormula(item, propName, formula) {
      var rootEntity = this.rootEntity
      const that = this
      try {
        return item[propName] = eval(formula)
      } catch (e) {
        console.error('ERROR: evaluateComplexItemFormula: ' + e.message)
      }

      return ''
    },

    evaluateFormulaAndAddSuffix(item, propName) {
      var res = this.evaluateFormula(item, propName)
      if (this.hasSuffix()) {
        res += ' ' + this.metadata.suffix
      }

      return res
    },

    getCalculatedValueHint(item, propName) {
      if (this.metadata && this.metadata.hint) {
        return this.metadata.hint
      } else {
        return this.evaluateFormulaAndAddSuffix(item, propName)
      }
    },

    selectedValueChanged(val) {
      if (this.onchanged) { 
        this.onchanged(this.entity, this.property, val)
      }
      else if (!!this.metadata.options && !!this.entity) {
        var option = this.metadata.options.find((n) => n.id == val)
        if (!!option && !!option.formula && option.formula.length > 0) {
          const item = this.entity
          try {
            eval(option.formula)
          } catch (e) {
            console.error('ERROR: evaluateFormula (of selectedValueChanged):')
            console.error(e.message)
          }
        }
      } else if(this.metadata.onChangeFormula && this.metadata.onChangeFormula.length > 0) {
        this.evalOnChangeFormula(this.entity, this.metadata.onChangeFormula)
      }
    },

    evalOnChangeFormula(item, formula) {
      const rootEntity = this.rootEntity
      try {
        eval(formula)
      } catch (e) {
        console.error(e)
      }
    },

    evaluateFormula(item, propName) {
      var rootEntity = this.rootEntity
      const that = this
      try {
        var result = eval(this.getFormula())
        item[propName] = result
        return result
      } catch (e) {
        console.error('ERROR: evaluateFormula: ' + e.message)
      }

      return ''
    },

    openLink(item) {
      if(item && item.value && eval(item.value).startsWith('method:')) {
        
        var method = eval(item.value).substring('method:'.length)
          this.$emit(
            'linkMethodCallback',
            this.getMethodName(method),
            this.getMethodArgs(method, item)
          )
      } else {
        this.navigateToLink(eval(this.getFormula()))
      }
    },

    emitLinkMethodCallback(method,args) {
      this.$emit('linkMethodCallback', method, args)
    },

    getMethodName(methodCall) {
      var paramsStart = methodCall.indexOf('(')
      return paramsStart <= 0 ? null : methodCall.substring(0, paramsStart)
    },

    getMethodArgs(methodCall, item) {
      var result = []

      var paramsStart = methodCall.indexOf('(')
      var paramsEnd = methodCall.indexOf(')')
      if (paramsStart <= 0 || paramsEnd <= 0 || paramsStart > paramsEnd) {
        console.error('Invalid method call: ' + methodCall)
        return result
      }

      var params = methodCall.substring(paramsStart + 1, paramsEnd)
      params.split(',').forEach((p) => result.push(eval(p)))
      return result
    },

    getLinkArrayItemText(itm) {
      // todo - for now it is implemented only for material order item linked components.
      // todo - display text if different from 'id' must be specified in metadata
      if (itm.externalRefId && itm.externalRefId.length > 0) {
        return `${itm.externalRefId} (${itm.id})`
      } else if (
        itm.component &&
        itm.component.externalRefId &&
        itm.component.externalRefId.length > 0
      ) {
        return itm.component.externalRefId
      } else if (itm.orderId) {
        return `${itm.orderId} (${itm.id})`
      } else if (!!itm.name && itm.name.length > 0) {
        return itm.name
      } else {
        return itm.id
      }
    },

    querySearchAsync(queryString, cb) {
      if (queryString && queryString.length >= AUTOCOMPLETE_MIN_CHARS) {
        clearTimeout(this.timeout)

        var formula = this.getFormula()
        var additionalConstraints = ''
        if (formula && formula.length > 0) {
          try {
            const rootEntity = this.rootEntity
            additionalConstraints = eval(this.getFormula())
          } catch (e) {
            console.error('ERROR: additionalConstraints eval formula:')
            console.error(e.message)
          }
        }

        this.timeout = setTimeout(() => {
          var uri = `v1/lookup?itemType=${this.metadata.lookupEntityType}&q=${queryString}`
          if (additionalConstraints && additionalConstraints.length > 0) {
            uri += '&' + additionalConstraints
          }
          this.$http
            .get(uri)
            .then(({ data }) => {
              cb(data)
            })
            .catch()
        }, 2000)
      } else {
        cb([])
      }
    },

    onRefreshComponent() {
      this.fillAutoCompleteObjectData({ data: { id: this.entity.id } })
    },

    fillAutoCompleteObjectData(obj) {
      // console.log(obj.data.id)

      this.$emit(
        'onAutoCompleteSelected',
        this.entity,
        this.property,
        this.metadata.lookupEntityType,
        obj.data
      )
    },

    onAutoCompleteBlur(obj) {
      /* TODO - Select this, if not selected so far and is only one result from the query!!!

    if(obj && obj.target && obj.target.value && obj.target.value.length >= AUTOCOMPLETE_MIN_CHARS) {
        console.log('--- blur ' + obj.target.value)
    }*/
    },

    getDateValue(src) {
      return !!src ? new Date(src).toISOString().split('T')[0] : null
    },

    setDateValue(ev) {
      ev.entity[ev.propName] = ev.$event.target.valueAsDate
    },

    getFormattedDate(src) {
      if (this.globalMetadata && this.globalMetadata.dateFormat && src) {
        var d = new Date(src)
        src = this.date2str(d, this.globalMetadata.dateFormat)
      }

      return src
    },

    getFormattedDateTime(src) { 
      if (this.globalMetadata && this.globalMetadata.dateFormat && src) {
        var d = new Date(src)
        src = this.date2str(d, this.globalMetadata.dateFormat + " hh:mm")
      }

      return src
    },

    date2str(x, y) {
      var z = {
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds(),
      }
      y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
        return ((v.length > 1 ? '0' : '') + eval('z.' + v.slice(-1))).slice(-2)
      })

      return y.replace(/(y+)/g, function (v) {
        return x.getFullYear().toString().slice(-v.length)
      })
    },

    getStatusClass(row, colId) {
      var hierObj = this.findHierObjectAndPropertyName(row, colId)

      if (row.alerts && row.alerts.length > 0) {
        return `in-progress-warning item-alerts`
      }

      if (hierObj.entity.status) {
        var st =
          this.globalMetadata.statuses[hierObj.entity.status] ||
          this.globalMetadata.statuses[hierObj.entity[hierObj.propName]]
        if (st) {
          return st.class
        }
      }

      return ''
    },

    getLabelClass() {
      var result = 'row-index'
      if (this.boldLabels) {
        result += ' varcontrol-bold-label'
      }

      return result
    },

    getLabelValue() {
      var result = this.entity[this.property]

      if (this.metadata && this.metadata.type == 'select') {
        // real data type
        for (var i = 0; i < this.metadata.options.length; i++) {
          if (this.metadata.options[i].id == result) {
            result = this.metadata.options[i].text
            break
          }
        }
      } else if (this.metadata && this.metadata.type == 'pricePerDurationUnits') { 
        result = this.toUserPricePerDurationUnits(this.entity[this.property])
      } else if (this.metadata && this.metadata.type == 'durationInUserUnits' || this.metadata.type == 'readonlyDurationInUserUnits') {
        result = this.toDurationInUserUnits(this.entity[this.property])
      }

      return result
    },

    getHint() {
      if (!this.metadata || !this.metadata.hint) {
        return null
      }

      const rootEntity = this.rootEntity
      const item = this.entity

      try {
        var result = eval(this.metadata.hint)
        return result
      } catch (e) {
        return this.metadata.hint
        //return this.metadata.hint
        console.error('ERROR: getHint (eval): ' + this.metadata.hint)
        console.error('this.metadata' , this.metadata)
        console.error(e.message)
      }

      return null
    },

    hasHint() {
      return this.getHint()
    },

    hasStatusLinkFormula() {
      var hasFormula =
        this.metadata &&
        this.metadata.formula &&
        this.metadata.formula.length > 1
      if (!hasFormula || !this.entity) {
        return false
      }

      var item = this.entity
      var link = eval(this.metadata.formula)

      return !!link
    },

    getComplexItems() {
      if (!this.isType('complex')) {
        return []
      }

      const item = this.entity  // don't remove - used for js eval
      const rootEntity = this.rootEntity  // don't remove - used for js eval

      var res = eval(this.metadata.formula)
      if (!res) {
        return []
      }

      res.forEach((itm) => {
        if (itm.value) {
          itm.value = '"' + itm.value + '"'
        }

        if(this.readOnly) {
          itm.type = getReadOnlyMetadataType(itm.type)
        }
      })

      return res
    },
  },
}
</script>

<style lang="scss">
li.focus-list {
  position: relative;
  z-index: 999;
}

textarea {
  height: 160px;
  width: 360px;
  min-width: 360px;
}

.varcontrol-bold-label {
  font-weight: bold;
}

.inactive-action-idx {
  font-size: x-small;
}


.fill-100-percent {
  width: 100%;
  height: 100%;
}
</style>
