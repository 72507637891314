const Customers = () => import('./main.vue')
const Form = () => import('./form.vue')

const meta = {
  requiresAuth: true,
}

export default [
  {
    name: 'customers.index',
    path: '/customers',
    component: Customers,
    meta,
    children: [
      {
        name: 'customers.new',
        path: 'create',
        component: Form,
        meta,
      },
      {
        name: 'customers.edit',
        path: ':id',
        component: Form,
        meta,
      },
    ],
  },
  {
        name: 'customers.new',
        path: '/customers/create',
        component: Form,
        meta,
      },
      {
        name: 'customers.edit',
        path: '/customers/:id',
        component: Form,
        meta,
      }
]
