<template>
  <div v-if="!isShopFloorWorkerUI()" class="top-header-line">
    <img
          src="@/assets/img/logo.png"
          style="max-width: 100px; max-height: 20px; float: left;margin-left: 20px; padding-top: 4px;"
        />

    <div class="top-header-line-info" v-if="isLogged">
      
      <img
        :src="getCompanyLogoSmall()"
        style="margin-right: 20px; height:16px; width:16px"
      /><span style="margin-right: 20px">{{ currentUser.company }}</span>
      <span>{{ currentUser.userName }}</span>
      <span style="margin-right: 40px"></span>

      
        
      <router-link to="/alerts" v-if="isLogged">
        <el-badge :value="getNotificationsCount()"  >
          <el-icon size="large" color="var(--main-menu-alt-text-color)"><Message/></el-icon>
        </el-badge>
      </router-link>
            
          
    </div>
    <!-- el-menu and its children comes from Element UI -->
    <!-- http://element.eleme.io/#/en-US/component/menu -->
    <!--THIS DOESN'T WORK: background-color="var(--main-menu-background-color)" -->
    <el-menu
      ref="mainMenu"
      mode="horizontal"
      background-color="#545c64"
      text-color="var(--main-menu-text-color)"
      active-text-color="var(--main-menu-active-text-color)"
      @select="navigate"
    >
      <template v-for="(menuItem, idx) in getMainMenuItems" >
        <div class="flex-grow" :key="idx" v-if="menuItem.id=='settings'" />
        <el-menu-item v-if="!menuItem.subMenuItems || menuItem.subMenuItems.length === 0" :index="menuItem.id" :key="idx" selected :class="menuItem.class">
          <template v-if="menuItem.icon">
              <el-icon><component :is="menuItem.icon" /></el-icon>
            </template>
          {{ translate(menuItem.text) }}
        </el-menu-item>
        <el-sub-menu v-else :index="menuItem.id" :key="idx+100">
          <template #title>
            <template v-if="menuItem.icon">
              <el-icon><component :is="menuItem.icon" /></el-icon>
            </template>
            {{ translate(menuItem.text) }}
          </template>
          <el-menu-item v-for="(subItem, subIdx) in menuItem.subMenuItems" :key="subIdx" :index="subItem.id">
            <template v-if="subItem.icon">
              <el-icon><component :is="subItem.icon" /></el-icon>
            </template>
            {{ translate(subItem.text) }}
          </el-menu-item>
        </el-sub-menu>
      </template>
    </el-menu>

  </div>
</template>

<script>
/* eslint-disable */
import { markRaw } from 'vue'
import {
  Setting,
  Message,
} from '@element-plus/icons-vue'

import { mapActions, mapGetters } from 'vuex'

import { version } from '../../config'

export default {
  components: {
    
    Setting: markRaw(Setting),
    Message: markRaw(Message),
  },
  name: 'CcNavBar',
  data: function () {
    return {
      mobileAppLinksDialogVisible: false,
    }
  },
  computed: {
    /**
     * See src/app/auth/vuex/getters.js
     */
    ...mapGetters([
      'currentUser',
      'isLogged',
      'isShopFloorWorker',
      //'mainMenuItems',
    ]),
    version() {
      return version
    },
  },
  watch: {
    isLogged(value) {
      // isLogged changes when the token changes
      if (value === false) {
        this.$router.push({ name: 'auth.signin' })
      } else {
      }
    },
  },
  methods: {
    /**
     * Makes logout() action available withint this component
     * See /src/app/auth/vuex/actions.js
     */

    ...mapActions(['logout']),

    /* eslint-disable no-undef */
    navigate(name) {
      switch (name) {
        case 'prodexis':
          window.location = 'https://prodexis.com'
          break
        case 'logout':
          this.logout()
          break
        default:
          this.$router.push({ name }, () => {})
          break
      }
    },

    isShopFloorWorkerUI() {
      return this.isShopFloorWorker
    },

    getCompanyLogoSmall() {
      return this.currentUser.companyLogoSmall &&
        this.currentUser.companyLogoSmall.data
        ? 'data:image/png;base64, ' + this.currentUser.companyLogoSmall.data
        : ''
    },

    getNotificationsCount() {
      return this.currentUser.notificationsCount > 0
        ? this.currentUser.notificationsCount
        : null
    },
  },
  mounted() {
    const baseUrl = process.env.BASE_URL
  },
  
  updated() {},
}
</script>

<style scoped>

.flex-grow {
  flex-grow: 0.6;
}

.menu-item-right {
  right: 8px;
   position: absolute;
}

</style>
