import Emitter from 'tiny-emitter'

const emitter = new Emitter()

export default {
  install: function (app) {
    app.config.globalProperties.$bus = {
      $on: (...args) => emitter.on(...args),
      $once:  (...args) => emitter.once(...args),
      $off: (...args) => emitter.off(...args),
      $emit: (...args) => emitter.emit(...args),
    }
    
  }
}