// https://vuex.vuejs.org/en/actions.html
import * as TYPES from './types'

export default {
  setFetching({ commit }, obj) {
    commit(TYPES.MAIN_SET_FETCHING, obj)
  },

  setTotalsMinimized({ commit }, obj) {
    commit(TYPES.MAIN_SET_TOTALSMINIMIZED, obj)
  },
}
