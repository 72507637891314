const Inquiries = () => import('./main.vue')
const Form = () => import('./form.vue')

const meta = {
  requiresAuth: true,
}

export default [
  {
    name: 'inquiries.index',
    path: '/inquiries',
    component: Inquiries,
    meta,
    
    children: [
      {
        name: 'inquiries.new',
        path: 'create',
        component: Form,
        meta,
      },
      {
        name: 'inquiries.edit',
        path: ':id',
        component: Form,
        meta,

      },
    ],
  },
  {
    name: 'inquiries.edit',
    path: '/inquiries/:id',
    component: Form,
    meta,

  },
  {
    name: 'inquiries.new',
    path: '/inquiries/create',
    component: Form,
    meta,

  }
]
