const Users = () => import('./main.vue')
const Form = () => import('./form.vue')

const meta = {
  requiresAuth: true,
}

const metaNoAuth = {
  requiresAuth: false,
}

export default [
  {
    name: 'users.index',
    path: '/users',
    component: Users,
    meta,
    children: [
      {
        name: 'users.new',
        path: 'create',
        component: Form,
        meta,
      },
      {
        name: 'users.edit',
        path: ':id',
        component: Form,
        meta,
      },
    ],
  },
  {
        name: 'users.new',
        path: '/users/create',
        component: Form,
        meta,
      },
      {
        name: 'users.edit',
        path: '/users/:id',
        component: Form,
        meta,
      }
]
