const Resources = () => import('./main.vue')
const WorkerForm = () => import('./workerform.vue')
const WorkcenterGroupForm = () => import('./workcentergroupform.vue')

const meta = {
  requiresAuth: true,
}

export default [
  {
    name: 'resources.index',
    path: '/resources',
    component: Resources,
    meta,
    children: [
      {
        name: 'resources/workers.new',
        path: 'workers/create',
        component: WorkerForm,
        meta,
      },
      {
        name: 'resources/workers.edit',
        path: 'workers/:id',
        component: WorkerForm,
        meta,
      },
      {
        name: 'resources/workcentergroups.new',
        path: 'workcentergroups/create',
        component: WorkcenterGroupForm,
        meta,
      },
      {
        name: 'resources/workcentergroups.edit',
        path: 'workcentergroups/:id',
        component: WorkcenterGroupForm,
        meta,
      },
    ],
  },
  {
        name: 'resources/workers.new',
        path: '/resources/workers/create',
        component: WorkerForm,
        meta,
      },
      {
        name: 'resources/workers.edit',
        path: '/resources/workers/:id',
        component: WorkerForm,
        meta,
      },
      {
        name: 'resources/workcentergroups.new',
        path: '/resources/workcentergroups/create',
        component: WorkcenterGroupForm,
        meta,
      },
      {
        name: 'resources/workcentergroups.edit',
        path: '/resources/workcentergroups/:id',
        component: WorkcenterGroupForm,
        meta,
      }
]
