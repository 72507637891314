<template>
  <div >

      <div v-if="!hasRows">
        <h3>{{ translate('No orders in manufacturing or orders matching the filter.') }}</h3>    
      </div>

      <cc-search  @searchFilterChanged="fetch" :metadata="metadata.filters" />

    <cc-var-list 
      v-loading.body="fetching"
      :items="rows"
      :metadata="metadata"
      baseroute="reports"
      @onAction="onAction"
      :readOnly="true"
      :hideActions="true"
    />

  </div>
  
  
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'CcProjectProgress',

  methods: {},

  props: {
    //reportDataUrl: null,
    //baseroute: null,
    //chartHeight: null,
  },

  data() {
    return {
      metadata: {},
      rows: [],
      currentFilter: '',
    }
  },

  mounted() {
    this.fetch()
  },

  computed: {
    ...mapGetters(['globalMetadata']),
    ...mapState({
      fetching: (state) => state.fetching,
    }),

    hasRows() {
      return this.rows && this.rows.length > 0
    }
  },

  methods: {
    ...mapActions(['setFetching']),

    fetch(searchOptions) {
      const baseUri = 'v1/reporting/projectprogress'
      var uri = this.buildUrl(
        baseUri,
        searchOptions,
        this.customerId,
        this.componentId)

      this.currentFilter = uri

      this.setFetching({ fetching: true })

      this.$http.get(uri).then((res) => {
        this.metadata = res.data.metadata
        this.rows = res.data.rows

        this.setFetching({ fetching: false })
      })
    },

    onAction(action, entity) {
      /* TODO - maybe we'll need to handle some actions in future
      switch (action.action) {
        case 'ordered':
          this.updateOrderStatus(action, entity)
          break

        default:
          console.error('Unhandled action: ' + action.action)
      }*/
    },
  },
}
</script>
