<template>
  <div>
    <cc-var-list
      :items="getDeliveries"
      :metadata="metadataList"
      baseroute="orders"
      @print="onPrint"
      @delete="deleteDelivery"
    />

    <h4>{{ translate('Create Handover Record') }}</h4>

    <el-form
      :model="newDelivery"
      ref="deliveryForm"
      class="handover-record"
      :rules="getFormValidationRules(metadata, order)"
    >
      <cc-var-group
        :metadata="metadata.generalInfo"
        :entity="newDelivery"
        :rootEntity="newDelivery"
        :showTitle="false"
      />

      <cc-var-list
        :items="getCreateNewDeliveryItems"
        :metadata="createHandoverRecordMetadata"
        baseroute="orders"
        hideActions="true"
        nonFixedFooter="true"
        @create="validateAndSubmit"
      />
    </el-form>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions } from 'vuex'

export default {
  name: 'CcOrderDeliveryHandover',

  components: {},

  data() {
    return {
      newDelivery: { provider: {} },

      createHandoverRecordMetadata: {
        columns: [
          {
            id: 'idx',
            text: '#',
          },
          {
            id: 'componentExternalRefId',
            text: this.translate('Ref.ID'),
          },
          {
            id: 'componentName',
            text: this.translate('Name'),
          },
          {
            id: 'totalQuantity',
            text: this.translate('Total Quantity'),
          },
          {
            id: 'delivered',
            text: this.translate('Delivered'),
          },
          {
            id: 'quantity',
            text: this.translate('Handover'),
            type: 'numeric',
          },
        ],
        allowedActions: [
          {
            action: 'create',
          },
        ],
      },

      deliveries: [],
      metadata: {},
      metadataList: {},
    }
  },

  props: {
    order: undefined,
    onClose: Function,
  },

  computed: {
    getDeliveries() {
      return this.deliveries || []
    },

    getOrderItems() {
      if (this.order && this.order.items && this.order.items.length > 0) {
        return this.order.items
      }

      return []
    },

    getCreateNewDeliveryItems() {
      var result = []

      if (this.getOrderItems) {
        var index = 0
        this.getOrderItems.forEach((n) =>
          result.push({
            idx: ++index,
            orderItemId: n.id,
            componentId: n.component.id,
            componentExternalRefId: n.component.externalRefId,
            componentName: n.component.name,
            totalQuantity: n.quantity,
            delivered: this.getDeliveredCount(n.id),
            quantity: 0,
          })
        )
      }

      return result
    },
  },

  watch: {
    order: function () {
      this.fetchDeliveries()
    },
  },

  methods: {
    fetchDeliveries() {
      var url = `v1/orders/${this.order.id}/deliveries`
      this.$http
        .get(url)
        .then(({ data }) => {
          this.metadata = data.metadata
          this.metadataList = data.metadataList
          this.deliveries = data.deliveries
        })
        .catch((error) => {
          this.showApiError(error)
        })
    },

    validateAndSubmit() {
      this.$refs['deliveryForm'].validate((valid) => {
        if(valid && this.validateDeliveryItems()) {
          this.create()
        }
      })
    },

    validateDeliveryItems() {
      var hasNonZero = false
      this.getCreateNewDeliveryItems.forEach((n) => {
        if (n.quantity <= 0) {
          n.quantity = 0
        } else {
          hasNonZero = true
        }
      })

      if (!hasNonZero) {
        this.$swal(
          'Warning',
          this.translate('There should be at least one delivery item.'),
          'warning'
        )
        return false
      }

      return true
    },

    create() {
      var url = `v1/orders/${this.order.id}/deliveries`
      var currentDelivery = this.getCurrentDelivery()
      currentDelivery.items = this.getItemsWithQuantity(currentDelivery.items)

      this.$http
        .post(url, currentDelivery)
        .then((res) => {
          this.deliveries = res.data.deliveries
          this.showApiSuccess(res.data)
        })
        .then(() => {
          this.onPrint(this.deliveries[this.deliveries.length - 1])
          this.clearCurrentDelivery()
          this.close()
        })
        .catch((error) => {
          this.showApiError(error)
        })
    },

    onPrint(delivery) {
      this.openFileDocument(`action=handoverrecord&ids=${delivery.id}`)
    },

    deleteDelivery(delivery) {
      var url = `v1/orders/${this.order.id}/deliveries/${delivery.id}`
      this.$http
        .delete(url)
        .then((res) => {
          this.deliveries = res.data.deliveries
          this.showApiSuccess(res.data)
        })
        .catch((error) => {
          this.showApiError(error)
        })
    },

    getCurrentDelivery() {
      return {
        type: this.type,
        deliveredBy: this.newDelivery.deliveredBy,
        receivedBy: this.newDelivery.receivedBy,
        expectedDeliveryTime: this.newDelivery.expectedDeliveryTime,
        items: this.getCreateNewDeliveryItems,
        provider: this.newDelivery.provider,
      }
    },

    getItemsWithQuantity(items) {
      var result = []
      var idx = 0
      items.forEach((n) => {
        if (n.quantity > 0) {
          var item = JSON.parse(JSON.stringify(n))
          item.idx = ++idx
          result.push(item)
        }
      })

      return result
    },

    clearCurrentDelivery() {
      this.newDelivery.deliveredBy = ''
      this.newDelivery.receivedBy = ''
      this.newDelivery.expectedDeliveryTime = new Date().setDate(
        new Date().getDate() + 2
      )
      this.getCreateNewDeliveryItems.forEach((n) => (n.quantity = 0))
      this.newDelivery.provider = {}
    },

    getDeliveredCount(itemId) {
      var result = 0
      this.getDeliveries.forEach((d) => {
        if (d.items) {
          d.items.forEach((item) => {
            if (item.orderItemId == itemId) {
              result += item.quantity
            }
          })
        }
      })

      return result
    },

    close() {
      if (this.onClose) {
        this.onClose()
      }
    },
  },

  mounted() {
    this.fetchDeliveries()
  },
}
</script>

<style lang="scss">
.handover-record .el-form-item__content {
  float: left;
  margin-left: 12px;
  min-width: 240px;
}
.handover-record .el-form-item__label {
  min-width: 160px;
}
.swal2-container {
  z-index: 9999 !important;
}
</style>
