const ShopFloorControl = () => import('./main.vue')

const meta = {
  requiresAuth: true,
}

export default [
  {
    name: 'shopfloorcontrol.index',
    path: '/shopfloorcontrol',
    component: ShopFloorControl,
    meta,
  },
]
