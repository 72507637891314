/*

import Vue from 'vue'
import Router from 'vue-router'
import beforeEach from './beforeEach'
import { routes as app } from '../app'

Vue.use(Router)

// https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Operators/Spread_operator
const routes = [...app]

const router = new Router({
  routes,
  linkActiveClass: 'active',
  mode: 'history', // do not use /#/.
})

router.beforeEach(beforeEach)

export default router*/

import { createRouter, createWebHistory } from 'vue-router'
import beforeEach from './beforeEach'
import { routes as app } from '../app'

// https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Operators/Spread_operator
const routes = [...app]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active',
})

/**
 * Before a route is resolved we check for
 * the token if the route is marked as
 * requireAuth.
 */
router.beforeEach(beforeEach)

export default router