const OrderControl = () => import('./main.vue')

const meta = {
  requiresAuth: true,
}

export default [
  {
    name: 'ordercontrol.index',
    path: '/ordercontrol',
    component: OrderControl,
    meta,
  }
]
