//import { SSL_OP_EPHEMERAL_RSA } from 'constants'
import { http } from '../plugins/http'
import buffer from 'buffer';

/* eslint-disable */
export default {
  // copy as reactive properties, thus they will remain same object
  copyProperties(to, from) {
    for (var property in from) {
      var descriptor = Object.getOwnPropertyDescriptor(from, property)

      if (
        descriptor &&
        (!descriptor.writable ||
          !descriptor.configurable ||
          !descriptor.enumerable ||
          descriptor.get ||
          descriptor.set)
      ) {
        Object.defineProperty(to, property, descriptor)
      } else {
        to[property] = from[property]
      }
    }

    return to
  },

  // copy as values, thus properties will be different objects
  copyValueProperties(to, from) {
    for (var prop in from) {
      if (from.hasOwnProperty(prop)) {
        // Vue.set(to, prop, from[prop])
        to[prop] = from[prop] // todo - test
      }
    }

    return to
  },

  findHierObjectAndPropertyName(entity, path) {
    const dom = path.split('.')
    
    for (var y = 0; y < dom.length - 1; y++) {
      if(!entity)
        break;

      entity = entity[dom[y]]
    }

    var propName = dom.length >= 1 ? dom[y] : ''

    return { entity: entity, propName: propName }
  },

  openFileDocument(id, dontShowPrint) {
    http
      .get(`v1/files/${id}`)
      .then((response) => {
        if (response.data.contentType == 'text/html') {
          var newWindow = window.open()
          //newWindow.document.body.innerHTML = response.data.contents
          newWindow.opener = null

          newWindow.document.write(response.data.contents)
          newWindow.document.close()

          if (!dontShowPrint) {
            // chrome renders the images with delay
            setTimeout(function () {
              newWindow.print()
              newWindow.close()
            }, 500)
          }
        } else if (
          response.data.contentType ==
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
          // download
          const a = document.createElement('a')
          a.href =
            'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
            response.data.contents
          a.download = response.data.name
          // a.target="_blank"
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        } else {
          this.openFileDocumentNew(
            response.data.contentType,
            response.data.name,
            response.data.contents
          )

          /* OLD Implementation - doesn't work on Chrome with big files (larger than 2MB +)

                var data = "data:" + response.data.contentType + ";base64," + response.data.contents

                var w = window.open('')

                setTimeout(function() {
                    //FireFox seems to require a setTimeout for this to work.
                    var fr = w.document.createElement('iframe')
                    fr.setAttribute("width", "100%")
                    fr.setAttribute("height", "100%")
                    w.document.body.appendChild(fr)
                        .src = data
                }, 0)*/
        }
      })
      .catch((error) => {
        this.showApiError(error)
      })
  },

  openFileDocumentNew(contentType, name, contents) {
    /*const byteCharacters = atob(contents)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)*/

    const byteArray = buffer.Buffer.from(contents, 'base64')

    var file = new Blob([byteArray], { type: contentType })
    var fileURL = URL.createObjectURL(file)
    window.open(fileURL, name)
  },

  showApiError(error) {
    console.error(error)
    this.$swal('Error', error.response.data.error.message, 'error')
  },

  /*deleteFileDocument(id) {
        return http.delete(`v1/files/${id}`)
        // todo - process errors / success ...
        .catch((error) => {
            this.showApiError(error)
          })
    },

    uploadFile(formData, containerType, containerId, propName) {
        return http.post(`v1/files?containerType=${containerType}&containerId=${containerId}&propName=${propName}`, formData)
            // get data
            .then(x => x.data)
    },*/

  decodeBase64(str) {
    var contents = str
    for (var i = 0; i < 2; i++) {
      var idx = contents.indexOf('/')
      contents = contents.substring(idx + 1)
    }

    var result = decodeURIComponent(
      atob(contents)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )

    return result
  },

  buildUrl(baseUri, searchOptions, customerId, componentId, orderId) {
    var query = ''
    var hasParams = baseUri && baseUri.indexOf('?') > 0

    if (customerId && 1 * customerId > 0) {
      query += '?customerId=' + customerId
    }

    if (componentId && 1 * componentId > 0) {
      query += (query.length > 0 ? '&' : '?') + `componentId=${componentId}`
    }

    if (orderId && 1 * orderId > 0) {
      query += (query.length > 0 ? '&' : '?') + `orderId=${orderId}`
    }

    if (searchOptions) {
      if (searchOptions.searchText) {
        query +=
          (query.length > 0 || hasParams ? '&' : '?') +
          `searchText=${searchOptions.searchText}`
      }
      if (searchOptions.options) {
        query +=
          (query.length > 0 || hasParams ? '&' : '?') +
          `options=${searchOptions.options.join(',')}`
      }
      if (searchOptions.dateRange) {
        query +=
          (query.length > 0 || hasParams ? '&' : '?') +
          `dateFrom=${this.getDateValue(searchOptions.dateRange[0])}`
        query +=
          (query.length > 0 || hasParams ? '&' : '?') +
          `dateTo=${this.getDateValue(searchOptions.dateRange[1])}`
      }
    }

    return baseUri + query
  },

  getDateValue(src) {
    try {
      return src ? src.toISOString().split('T')[0] : null
    } catch (ex) {
      return null
    }
  },
}
