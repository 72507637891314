const MaterialOrders = () => import('./main.vue')
const Form = () => import('./form.vue')

const meta = {
  requiresAuth: true,
}

export default [
  {
    name: 'materialorders.index',
    path: '/materialorders',
    component: MaterialOrders,
    meta,
    children: [
      {
        name: 'materialorders.new',
        path: 'create',
        component: Form,
        meta,
      },
      {
        name: 'materialorders.edit',
        path: ':id',
        component: Form,
        meta,
      },
    ],
  },
  {
        name: 'materialorders.new',
        path: '/materialorders/create',
        component: Form,
        meta,
      },
      {
        name: 'materialorders.edit',
        path: '/materialorders/:id',
        component: Form,
        meta,
      }
]
