const WorkCenters = () => import('./main.vue')

const meta = {
  requiresAuth: true,
}

export default [
  {
    name: 'workcenters.index',
    path: '/workcenters',
    component: WorkCenters,
    meta,
    children: [
      {
        name: 'workcenters',
        path: ':id',
        component: WorkCenters,
        meta,
      },
    ],
  },
  {
        name: 'workcenters',
        path: '/workcenters/:id',
        component: WorkCenters,
        meta,
      }
]