import * as TYPES from '../../store/types'

const state = {
  tenant: {},
  metadata: {},
  readOnly: false,
}

/* eslint-disable no-param-reassign */
const mutations = {
  [TYPES.CORPORATESETTINGS_SET_DATA](st, obj) {
    if (obj.tenant) {
      st.tenant = obj.tenant
    }

    if (obj.metadata) {
      st.metadata = obj.metadata
    }

    st.readOnly = obj.readOnly
  },
}

const actions = {
  corporateSettingsSetData({ commit }, obj) {
    commit(TYPES.CORPORATESETTINGS_SET_DATA, obj)
  },
}

const module = {
  state,
  mutations,
  actions,
}

export default { module }
