import { createStore } from 'vuex'
import state from './state'
import mutations from './mutations'
import actions from './actions'
import modules from './modules'
import plugins from './plugins'
//import * as getters from './getters'

export default createStore({
  state,
  mutations,
  actions,
  modules,
  //plugins: [createPersistedState()],
  plugins,
  //getters,
  strict: false, //process.env.NODE_ENV !== 'production',
})