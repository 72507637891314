/*
http://element.eleme.io/#/en-US/component/form

https://jsfiddle.net/api/post/library/pure/

*/
/* eslint-disable */
export default {
  rules: {
    required: [
      { required: true, message: "Please input '${text}'", trigger: 'blur' },
    ],

    /*
    name: [
      { required: true, message: 'Please input name', trigger: 'blur' },
    ],*/

    currency: [
      { required: true, message: 'Please input currency', trigger: 'blur' },
      { min: 1, max: 3, message: 'Length should be 1 to 3', trigger: 'blur' },
    ],

    address: [
      { required: true, message: 'Please input address', trigger: 'blur' },
    ],

    tel: [
      { required: true, message: 'Telephone number is required' },
      /*{ type: 'number', min: 0, message: 'Must be a number'}*/
    ],

    greaterThanZero: [
      {
        required: true,
        type: 'number',
        min: 1,
        message: 'Must be greater than zero',
      },

      /*{
        validator: (rule, value, callback) => {
          if (isNaN(parseFloat(value)) || !isFinite(value) || 1 * value < 1) {  // is NOT numeric
            callback(new Error("'${text}' must be greater or equal to 1"))
          } else {
            callback();
          }
      }, trigger: 'blur' }*/
    ],

    customerMustBeSelected: [
      {
        required: true,
        type: 'number',
        min: 1,
        message: 'Customer must be selected',
      },
    ],

    email: [
      {
        required: true,
        message: 'Please input email address',
        trigger: 'blur',
      },
      {
        type: 'email',
        message: 'Please input correct email address',
        trigger: 'blur',
      },
    ],

    password: [
      { required: true, message: 'Please input password', trigger: 'blur' },
    ],

    confirmPassword: [
      {
        required: true,
        message: 'Please input confirm password',
        trigger: 'blur',
      },
    ],

    /* NOT USED ARE BELLOW!!! */

    region: [
      {
        required: true,
        message: 'Please select Activity zone',
        trigger: 'change',
      },
    ],
    date1: [
      {
        type: 'date',
        required: true,
        message: 'Please pick a date',
        trigger: 'change',
      },
    ],
    date2: [
      {
        type: 'date',
        required: true,
        message: 'Please pick a time',
        trigger: 'change',
      },
    ],
    type: [
      {
        type: 'array',
        required: true,
        message: 'Please select at least one activity type',
        trigger: 'change',
      },
    ],
    resource: [
      {
        required: true,
        message: 'Please select activity resource',
        trigger: 'change',
      },
    ],
    desc: [
      {
        required: true,
        message: 'Please input activity form',
        trigger: 'blur',
      },
    ],
  },

  getRules() {
    return this.rules
  },

  getFormRules(metadata, model, funcTranslate) {
    var result = {}

    if (metadata) {
      if (metadata.generalInfo && metadata.generalInfo.columns) {
        this.fillFormRulesFromColumns(
          metadata.generalInfo.columns,
          result,
          funcTranslate
        )
      }

      if (metadata.itemsMetadata && metadata.itemsMetadata.columns) {
        this.fillFormRulesFromColumns(
          metadata.itemsMetadata.columns,
          result,
          funcTranslate
        )
      }

      if (
        metadata.item &&
        metadata.item.info &&
        metadata.item.info.columns &&
        model &&
        model.items
      ) {
        this.fillFormComponentRulesFromColumns(
          metadata.item.info.columns,
          model.items.length,
          result,
          funcTranslate
        )
      }

      if (metadata.sendEmailSettings && metadata.sendEmailSettings.columns) {
        this.fillFormRulesFromColumns(
          metadata.sendEmailSettings.columns,
          result,
          funcTranslate
        )
      }
    }

    return result
  },

  fillFormRulesFromColumns(columns, result, funcTranslate) {
    for (var colIdx = 0; colIdx < columns.length; colIdx++) {
      for (var i = 0; i < columns[colIdx].length; i++) {
        var meta = columns[colIdx][i]
        if (meta.rule && meta.rule.length > 0) {
          var propName = this.getValidationPropertyName(meta)
          var validationRule = this.buildValidationRule(meta, funcTranslate)
          if (propName && validationRule) {
            result[propName] = validationRule
          }
        }
      }
    }
  },

  fillFormComponentRulesFromColumns(
    columns,
    componentsCount,
    result,
    funcTranslate
  ) {
    for (var colIdx = 0; colIdx < columns.length; colIdx++) {
      for (var i = 0; i < columns[colIdx].length; i++) {
        var meta = columns[colIdx][i]
        if (meta.rule && meta.rule.length > 0) {
          if (meta.id.startsWith('item.')) {
            for (var compIdx = 0; compIdx < componentsCount; compIdx++) {
              var propName = this.getValidationPropertyName(meta, compIdx)
              var validationRule = this.buildValidationRule(meta, funcTranslate)
              if (propName && validationRule) {
                result[propName] = validationRule
              }
            }
          }
        }
      }
    }
  },

  buildValidationRule(meta, funcTranslate) {
    var result = this.rules[meta.rule]
    if (result) {
      var ruleTxt = JSON.stringify(result)
      var text = meta.text ? meta.text : meta.id
      result = JSON.parse(ruleTxt)
      result.forEach((n) => {
        if (n.message) {
          // console.log(n.message + ' -> ' + funcTranslate(n.message).replace('${text}', text))
          n.message = funcTranslate(n.message).replace('${text}', text)
        }
      })
    }

    return result
  },

  getValidationPropertyName(cellMetadata, itemIdx) {
    if (cellMetadata.rule) {
      if (cellMetadata.type.endsWith('Array')) {
        return `${cellMetadata.id}[0].value`
      } else if (cellMetadata.id.startsWith('item.')) {
        var id = cellMetadata.id.substring('item.'.length)
        if (itemIdx == undefined) {
          console.error('getValidationPropertyName - itemIdx is undefined!')
        }
        return `items[${itemIdx}].${id}`
      } else {
        return cellMetadata.id
      }
    }

    return null
  },
}
