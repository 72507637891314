const MobileApp = () => import('./main.vue')

const meta = {
  requiresAuth: true,
}

export default [
  {
    name: 'mobileapp.index',
    path: '/mobileapp',
    component: MobileApp,
    meta,
  },
]
