<template>
  <div >
    <div v-for="(file, fileIdx) in getFiles()" :key="fileIdx">
      <a :title="getFileName(file)"
        href="javascript:void(0);"
        v-on:click="openFileDocumentInternal(file.id)"
        >{{ getFileName(file) }}</a
      >
      <el-button
        v-if="!readonly"
        @click="removeFileDocument(file.id)"
        type="danger"
        :icon="Delete"
        size="small"
      ></el-button>
    </div>

    <span v-if="shouldShowNoFileText()"  :title="getFileName()">{{ getFileName() }}</span>

    <div v-if="!readonly" >
      <form
        enctype="multipart/form-data"
        novalidate
        v-if="isInitial || isSaving"
        style="line-height: 28px"
      >
        <div
          :class="['dropbox', dragging ? 'dragover' : '']"
          drag-over="handleDragOver"
          @dragenter="dragging = true"
          @dragleave="dragging = false"
        >
          <input
            type="file"
            :name="uploadFieldName"
            :disabled="isSaving"
            multiple
            @change="
              filesChange($event.target.name, $event.target.files);
              this.fileCount = $event.target.files.length
            "
            accept="image/*, text/html, application/pdf"
            class="input-file"
          />

          <el-icon class="upload-img" style="font-size: 32px"><Upload/></el-icon>

          
          <!--Drag your file(s) here to begin<br> or click to browse-->
          <p v-if="isInitial">
            {{ translate('Add new file') }}<br />({{
              translate('Click or add the files here')
            }})
          </p>
          <p v-if="isSaving">{{ getUploadinText() }}</p>
        </div>
      </form>
      <!--SUCCESS-->
      <!--<div v-if="isSuccess">
          <h2>Uploaded  successfully.</h2>
        </div> -->
      <!--FAILED-->
      <div v-if="isFailed">
        <h2>Uploaded failed.</h2>
        <p>
          <a href="javascript:void(0)" @click="reset()">Try again</a>
        </p>
        <pre>{{ uploadError }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
import { Delete  } from '@element-plus/icons-vue'
import { markRaw } from 'vue'

const STATUS_INITIAL = 0
const STATUS_SAVING = 1
const STATUS_SUCCESS = 2
const STATUS_FAILED = 3

export default {
  data() {
    return {
      uploadError: null,
      currentStatus: null,
      uploadFieldName: '',
      currentTemporaryFileId: -1,
      dragging: false,
      Delete: markRaw(Delete),
    }
  },
  name: 'CcFilesList',
  props: {
    readonly: false,
    propName: '',
    files: null,
    formula: null,
    entity: null,
  },
  computed: {
    isInitial() {
      return (
        this.currentStatus === STATUS_INITIAL ||
        this.currentStatus === STATUS_SUCCESS
      )
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED
    },
  },
  methods: {
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL
      this.uploadError = null
      this.currentTemporaryFileId = -1
    },

    /* save(formData) {
  // upload data to the server
  this.currentStatus = STATUS_SAVING;

  this.uploadFile(formData, this.containerType, this.containerId, this.propName)
    .then(x => {
      this.files.splice(0, this.files.length)
      for (var fi = 0; fi < x.files.length; fi++) {
        this.files.push(x.files[fi])
      }
      this.currentStatus = STATUS_SUCCESS;
    })
    .catch(err => {
      this.uploadError = err.response;
      this.currentStatus = STATUS_FAILED;
    })
},*/

    filesChange(fieldName, fileList) {
      // handle file changes
      /* const formData = new FormData();
  if (!fileList.length) {
    return
  }

  /*if (!this.autoSubmitFiles || !this.containerId || 1*this.containerId <= 0) {
    // parent still not created -> add it to the temporarty list
    for (var i = 0; i < fileList.length; i++) {
      this.appendNotSubmitedFile(fileList[i])
    }
  } else {
    // append the files to FormData
    // eslint-disable array-callback-return
    Array
      .from(Array(fileList.length).keys())
      .map(x => {
        formData.append(fieldName, fileList[x], fileList[x].name)
      })

    // save it
    this.save(formData)
  }*/

      if (!fileList.length) {
        return
      }

      for (var i = 0; i < fileList.length; i++) {
        this.appendNotSubmitedFile(fileList[i])
      }
    },

    removeFileDocument(id) {
      /*if (!this.autoSubmitFiles || id < 0) {
    this.removeFileFromFilesList(id)
  }
  else {
    this.deleteFileDocument(id)
      .then(n => this.removeFileFromFilesList(id))
  }*/
      this.removeFileFromFilesList(id)
    },

    removeFileFromFilesList(id) {
      var idx = this.getFileIndex(id)
      if (idx >= 0) {
        this.files.splice(idx, 1)
      }
    },

    getFiles() {
      if (!this.files) {
        return []
      } else if (!Array.isArray(this.files)) {
        return [this.files]
      } else {
        return this.files
      }
    },

    getFileIndex(id) {
      for (var i = 0; i < this.files.length; i++) {
        if (this.files[i].id == id) {
          return i
        }
      }

      return -1
    },

    openFileDocumentInternal(id) {
      if (id <= 0) {
        var idx = this.getFileIndex(id)
        if (
          idx >= 0 &&
          this.files[idx].id < 0 &&
          !!this.files[idx]._notSubmitedContents
        ) {
          this.openNotSubmitedFileDocument(this.files[idx])
        }
      } else {
        this.openFileDocument(id)
      }
    },

    appendNotSubmitedFile(file) {
      /*
  this.notSubmitedFiles.push(fileList[i])
  this.files.push({id: this.currentTemporaryFileId, name: fileList[i].name})
  */
      var id = this.currentTemporaryFileId
      var currentFiles = this.files
      this.currentTemporaryFileId--
      var reader = new FileReader()
      reader.onload = function () {
        currentFiles.push({
          id: id,
          name: file.name,
          _notSubmitedContents: reader.result,
        })
      }
      reader.onerror = function (error) {
        console.error('Error: ', error)
      }

      reader.readAsDataURL(file)
    },

    openNotSubmitedFileDocument(file) {
      /* Old way doesn't work on Chrome


  const a = document.createElement("a");
  a.href = file._notSubmitedContents
  a.openFile = file.name
  // a.download = response.data.name; // if we need to download it!!!
  a.target="_blank"
  document.body.appendChild(a);
  a.click();*/

      // base64:
      //    data:text/plain;base64,VEVTVCBDVVNUT0
      const base64str = ';base64,'
      var pos = file._notSubmitedContents.indexOf(base64str)
      const contentType = file._notSubmitedContents.substring(5, pos)
      //console.log('contentType: ' + contentType)
      var contents = file._notSubmitedContents.substring(pos + base64str.length)
      //console.log('contents: ' + contents)

      this.openFileDocumentNew(contentType, file.name, contents)
    },

    getUploadinText() {
      return `Uploading ${this.fileCount} files...`
    },

    getFileName(file) {
      if (this.formula && this.entity) {
        try {
          const item = this.entity
          var result = eval(this.formula)
          return result
        } catch (e) {
          console.error('ERROR: evaluateFormula: ' + this.formula)
          console.error(e.message)
        }
      } else if (file) {
        return file.name
      } else {
        return ''
      }
    },

    shouldShowNoFileText() {
      return (
        this.readonly &&
        this.getFiles().length == 0 &&
        this.formula &&
        this.entity
      )
    },
  },
  mounted() {
    this.reset()
  },
}
</script>

<style lang="scss"></style>
