<template>
  <div class="main">
    <div class="row">
      <div class="col-md-4">
        <h1>{{ translate(metadata.title)}}</h1>
      </div>

      <div class="col-md-4" style="padding-top: 18px">
        <cc-search @searchFilterChanged="filter" />
      </div>

      <div v-if="!hideTimeFilter" class="col-md-4" style="padding-top: 18px">
        <el-date-picker
          :shortcuts="getPickerOptions && getPickerOptions.shortcuts"
          :disabled-date="getPickerOptions && getPickerOptions.disabledDate"
          :cell-class-name="getPickerOptions && getPickerOptions.cellClassName"
          v-model="getSearchFilter.dateRange"
          type="daterange"
          unlink-panels
          range-separator="To"
          style="float: right"
          start-placeholder="From"
          end-placeholder="To"
          :format="getDateFormat"
          @change="selectedDateRangeChanged"
        >
        </el-date-picker>
      </div>
    </div>

    <!-- https://developers.google.com/chart/interactive/docs/gallery/timeline -->
    <!-- https://github.com/devstark-com/vue-google-charts -->
    <!-- https://developers.google.com/chart/interactive/docs/gallery/ganttchart -->
    <!-- <GChart :settings="{ packages: ['timeline'] }" type="Timeline" :data="chartData" :options="getChartOptions" /> -->

    <div v-if="showTable" class="group" >
        <div class="left">
          <h4>{{ translate(timelineTitle) }}</h4>
          <GChart
          :settings="{ packages: ['timeline'] }"
          type="Timeline"
          :data="chartData"
          :options="getChartOptions"
          style="background-color: transparent"
        />
          </div>
        <div class="right">
          <h4>{{ translate('Total') }}</h4>
           <cc-var-list
              v-loading.body="fetching"
              :metadata="tableReportTotalMetadata"
              :items="filteredTotalRows"
              :baseroute="baseroute"
              :hideActions="true"
            />
  <h4>{{ translate('Work Logs') }}</h4>
          <cc-var-list
            v-loading.body="fetching"
            :metadata="tableReportMetadata"
            :items="filteredRows"
            :baseroute="baseroute"
            :hideActions="true"
          />
        </div>
    </div>
    <div v-else>
      <GChart
        :settings="{ packages: ['timeline'] }"
        type="Timeline"
        :data="chartData"
        :options="getChartOptions"
        style="background-color: transparent"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { GChart } from 'vue-google-charts'

export default {
  name: 'CcTimeline',

  components: {
    GChart,
  },

  props: {
    reportDataUrl: null,
    isHistory: null,
    hideTimeFilter: {
      type: Boolean,
      default: false,
    },
    showTable: false,
    timelineTitle: '',
    baseroute:''
  },

  data() {
    return {
      chartOptions: {
        timeline: {
          colorByRowLabel: true,
          rowLabelStyle: {
            backgroundColor: 'transparent',
          },
          barLabelStyle: {
            backgroundColor: 'transparent',
          },
        },

        height: 10000,
        //forceIFrame: true,
        backgroundColor: 'transparent',
      },

      metadata: {},
      tableReportMetadata: {},
      tableReportTotalMetadata: {},
      reportRows: [],

      searchFilterHistory: {
        dateRange: [
          new Date(new Date().getTime() - 3600 * 1000 * 24 * 30),
          new Date(new Date().getTime()),
        ],
      },
      searchFilterPlanning: {
        dateRange: [],
      },
      pickerOptionsHistory: {
        shortcuts: [
          {
            text: 'Last month',
            value: () => {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              return [start, end]
            },
          },
          {
            text: 'Last 3 months',
            value: () => {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              return [start, end]
            },
          },
          {
            text: 'Last 6 months',
            value: () => {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
              return [start, end]
            },
          },
        ],
      },
      pickerOptionsPlanning: {
        shortcuts: [
          {
            text: '[All]',
            value: () => {
              return [null, null]
            },
          },
          {
            text: 'Week',
            value: () => {
              const end = new Date()
              const start = new Date()
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 7)
              return [start, end]
            },
          },
          {
            text: '2 weeks',
            value: () => {
              const end = new Date()
              const start = new Date()
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 14)
              return [start, end]
            },
          },
          {
            text: 'Month',
            value: () => {
              const end = new Date()
              const start = new Date()
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 30)
              return [start, end]
            },
          },
        ],
      },
      searchText: '',
    }
  },

  mounted() {
    this.fetch()

    window.onresize = () => {
      setTimeout(() => {
        this.updateChartStyles()
      }, 1250)
    }
  },

  watch: {
    $route: 'fetch',

    reportDataUrl: 'fetch',
  },

  computed: {
    ...mapGetters(['globalMetadata']),

    ...mapState({
      fetching: (state) => state.fetching,
    }),

    getChartOptions() {
      var result = this.chartOptions

      var backgroundColor =
        document.body.style.getPropertyValue('--background-color')
      var color = document.body.style.getPropertyValue('--main-text-color')

      if (backgroundColor) {
        result.backgroundColor = backgroundColor
      }

      if (color) {
        result.timeline.barLabelStyle.color = color
        result.timeline.rowLabelStyle.color = color
      }

      return result
    },

    // Array will be automatically processed with visualization.arrayToDataTable function
    chartData() {
      var result = []
      if (!this.metadata || !this.metadata.columns) {
        return undefined
        // return [['A', 'B', 'Start', 'End']]
      }

      var header = []
      //this.metadata.columns.forEach((n) => header.push(n.text))
      this.metadata.columns.forEach((n) => { header.push(n) })
      result.push(header)

      this.filteredRows.forEach((r) => {
        var item = []
        this.metadata.columns.forEach((col) => item.push(this.getValue(col, r)))
        result.push(item)
      })

      return result
    },

    filteredRows() {
      const minSearchText = 3

      if (
        !this.searchText ||
        this.searchText.length < minSearchText ||
        !this.reportRows ||
        this.reportRows.length == 0
      )
        return this.reportRows

      var searchText = this.searchText.toLowerCase()

      var matchesFilter = (row, searchText) => {
        return row && JSON.stringify(row).toLowerCase().indexOf(searchText) >= 0
      }

      var result = []
      this.reportRows.forEach((a) => {
        if (matchesFilter(a, searchText)) {
          result.push(a)
        }
      })

      return result
    },

    filteredTotalRows() { 
      var resultByActionId = {}

      // group by actionId and sum total's of the worklogs per action
      this.filteredRows.forEach(row => { 
        var item = resultByActionId[row.__actionId] || {
          workcenterWorker: row.workcenterWorker,
          __order: row.__order,
          __orderItem: row.__orderItem,
          __plannedDurationInMinutes: row.__plannedDurationInMinutes,
          __durationInMinutes: 0,
        }

        item.__durationInMinutes += row.__durationInMinutes
        resultByActionId[row.__actionId] = item
      })

      var result = {}
      // group by report columns and sum work logs vs planned
      for (const actionId in resultByActionId) {
        var row = resultByActionId[actionId]
        var grpKey = `${row.workcenterWorker}_${row.__order}_${row.__orderItem}`
        var item = result[grpKey] || {
          workcenterWorker: row.workcenterWorker,
          __order: row.__order,
          __orderItem: row.__orderItem,
          __plannedDurationInMinutes: 0,
          __durationInMinutes: 0,
        }

        item.__plannedDurationInMinutes += row.__plannedDurationInMinutes
        item.__durationInMinutes += row.__durationInMinutes
        result[grpKey] = row
      }

      var resultRows = []
      var totalPlanned = 0;
      var totalDuration = 0;
      for (const grp in result) {
        resultRows.push(result[grp])
        totalPlanned += result[grp].__plannedDurationInMinutes
        totalDuration += result[grp].__durationInMinutes
      }

      // insert total
      resultRows.push({ __order: '', __orderItem:'TOTAL', __plannedDurationInMinutes: totalPlanned, __durationInMinutes: totalDuration})

      return resultRows
    },

    getDateFormat() {
      if (this.globalMetadata && this.globalMetadata.dateFormat) {
        return this.globalMetadata.dateFormat.toUpperCase()
      }

      return 'yyyy-MM-dd'.toUpperCase()
    },

    getPickerOptions() {
      return this.isHistory
        ? this.pickerOptionsHistory
        : this.pickerOptionsPlanning
    },

    getSearchFilter() {
      return this.isHistory
        ? this.searchFilterHistory
        : this.searchFilterPlanning
    },
  },

  methods: {
    ...mapActions(['setFetching']),

    fetch() {
      var uri = this.reportDataUrl
      if (!uri) {
        return
      }

      if (this.getSearchFilter && this.getSearchFilter.dateRange) {
        uri = this.buildUrl(uri, this.getSearchFilter)
      }

      this.setFetching({ fetching: true })

      this.$http.get(uri).then((res) => {
        this.metadata = res.data.metadata
        this.tableReportMetadata = res.data.tableReportMetadata
        this.tableReportTotalMetadata = res.data.tableReportTotalMetadata
        this.reportRows = res.data.reportRows

        this.setFetching({ fetching: false })

        setTimeout(() => {
          this.updateChartStyles()
        }, 100)
      })
    },

    daysToMilliseconds(days) {
      return days * 24 * 60 * 60 * 1000
    },

    getValue(col, r) {
      var val = r[col.id]
      if (col.type == 'date') {
        return new Date(val)
      }
      return val
    },

    selectedDateRangeChanged(val) {
      this.fetch()
    },

    updateChartStyles() {
      var styles = `<style>text { fill: ${document.body.style.getPropertyValue(
        'color'
      )}; }</style>`
      var svg = document.getElementsByTagName('svg')
      if (svg && svg[0] && svg[1]) {
        svg[0].innerHTML += styles
        // svg[1].innerHTML += styles
      }
    },

    filter(searchOptions) {
      this.searchText = searchOptions.searchText
    },
  },
}
</script>

<style scoped></style>
