import * as TYPES from '../../store/types'

const state = {
  externalCooperations: [],
  externalCooperations_metadata: {},
  readOnly: false,
}

/* eslint-disable no-param-reassign */
const mutations = {
  [TYPES.EXTERNALCOOPERATIONS_SET_DATA](st, obj) {
    if (obj.externalCooperations) {
      st.externalCooperations = obj.externalCooperations
    }

    if (obj.metadata) {
      st.externalCooperations_metadata = obj.metadata
    }

    st.readOnly = !!obj.readOnly
  },
}

const actions = {
  externalCooperationsSetData({ commit }, obj) {
    commit(TYPES.EXTERNALCOOPERATIONS_SET_DATA, obj)
  },
}

const module = {
  state,
  mutations,
  actions,
}

export default { module }
