import * as TYPES from '../../store/types'

const state = {}

/* eslint-disable no-param-reassign */
const mutations = {}

const actions = {}

const module = {
  state,
  mutations,
  actions,
}

export default { module }
