<template>
  <div class="main">
    <GChart type="BarChart" :data="chartData" :options="getChartOptions" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GChart } from 'vue-google-charts'

/* eslint-disable */
export default {
  name: 'CcBarChart',

  components: {
    GChart,
  },

  props: {
    metadata: null,
    rows: null,
    chartHeight: null,
  },

  data() {
    return {
      chartOptions: {
        isStacked: true,
        
        minHeight: 240,
        height: 640,
        // backgroundColor: '#1B1B1B',
        chartArea: {
          backgroundColor: 'transparent',
          height:'98%',
        },
        hAxis: {
          textStyle: {
            // color: '#ffffff',
          },
          gridlines: {
            color: 'transparent',
          },
        },
        legend: {
          position: 'right',
          maxLines: 3,
          textStyle: {
            // color: '#ffffff',
          },
        },
        vAxis: {
          textStyle: {
            // color: '#ffffff',
          },
          gridlines: {
            color: 'transparent',
          }
        },
      },

      reportMetadata: {},
      reportRows: [],

      selectedFilteringPeriod: '1Y',
    }
  },

  mounted() {
  },

  computed: {
    ...mapGetters(['globalMetadata']),

    getChartOptions() {
      var result = this.chartOptions

      var backgroundColor =
        document.body.style.getPropertyValue('--background-color')
      var color = document.body.style.getPropertyValue('--main-text-color')

      if (backgroundColor) {
        result.backgroundColor = backgroundColor
      }

      if (color) {
        result.legend.textStyle.color = color
        result.vAxis.textStyle.color = color
        result.hAxis.textStyle.color = color
      }

      if (this.chartHeight && this.chartHeight > 0)
        result.height = this.chartHeight
      else {
        result.height = this.rows.length * 26
        if(result.minHeight > result.height)
          result.height = result.minHeight
      }

      result.height+=120

      if (this.metadata.title) {
        result.title = this.translate(this.metadata.title)
        result.titleTextStyle = {
          color: color,
          fontSize: 18,
          bold: false,
        }
      }

      result.colors = ['seagreen', '#CF0000', 'orange'],
      result.series = {
          0: {color: result.colors[0]}, // Color for Item 1 - Remaining Time
          1: {color: result.colors[1]}, // Color for Item 2 - 
          2: {color: result.colors[2]}  // Color for Item 3 - 
        }

      return result
    },

    // Array will be automatically processed with visualization.arrayToDataTable function
    chartData() {
      /* example
        return [
          ['Supplier', '% Early', '% On-Time', '% Late'],
          ['Supplier 1',  20, 45, 35],
          ['Supplier 2',  0, 80, 20],
          ['Supplier 3',  50, 50, 0],
          ['Supplier 4',  30, 30, 40],
          ] */

      var result = []
      if (!this.metadata || !this.metadata.columns) {
        return [['Supplier', '% Early', '% On-Time', '% Late']]
      }

      var header = []
      this.metadata.columns.forEach((n) => header.push( n.text ? this.translate(n.text) : n))
      result.push(header)

      this.rows.forEach((r) => {
        var item = []
        this.metadata.columns.forEach((col) => item.push(this.getValue(col, r)))
        result.push(item)
      })

      return result
    },

    getFilterOptions() {
      return this.metadata && this.metadata.filters && this.metadata.filters.options
        ? this.metadata.filters.options
        : []
    },
  },

  methods: {

    getValue(col, r) {
      var val = r[col.id]
      if (col.type == 'date') {
        return new Date(val)
      }
      return val
    },
  },
}
</script>
