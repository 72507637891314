<template>
  <div class="app-title-action-buttons">
    <div v-for="(action, aactIdx) in getAllowedActions()" :key="aactIdx + 100">
      <el-button
        v-if="isAllowedAction(action)"
        :class="getClass"
        :type="getType(action)"
        style="margin-left: 10px"
        @click="confirm(action)"
        :title="translate(action.title)"
        :icon="action.icon"
        size="small"
        >{{ translate( action.buttonTitle )}}</el-button
      >
    </div>
  </div>
</template>

<script>

import Vue, { markRaw } from 'vue'
import {
  ArrowUp,
  ArrowDown,
  CaretRight,
  Timer,
  Check,
  Refresh,
  Edit,
  Printer,
  Delete,
  View,
  ShoppingCartFull,
  CircleCheck,
  Document,
  Connection,
  CircleClose
} from '@element-plus/icons-vue'

/* eslint-disable */
export default {
  name: 'CcActions',

  props: {
    allowedActions: null,
    entity: {},
    isListActionButtons: false,

    ArrowUp: markRaw(ArrowUp),
    ArrowDown: markRaw(ArrowDown),
    CaretRight: markRaw(CaretRight),
    Timer: markRaw(Timer),
    Check: markRaw(Check),
    Refresh: markRaw(Refresh),
    Edit: markRaw(Edit),
    Printer: markRaw(Printer),
    Delete: markRaw(Delete),
    View: markRaw(View),
    ShoppingCartFull: markRaw(ShoppingCartFull),
    CircleCheck: markRaw(CircleCheck),
    Document: markRaw(Document),
    Connection: markRaw(Connection),
    CircleClose: markRaw(CircleClose),
  },

  computed: {
    getClass() {
      return this.isListActionButtons ? '' : 'header-button'
    },
  },

  methods: {
    getAllowedActions() {
      return this.allowedActions ? this.allowedActions : []
    },

    getNextStatus(action) {
      if (action.prev && action.prev.length > 0) {
        var idx = action.prev.indexOf(this.entity.status)
        if (idx >= 0) {
          return action.next
        }
        if (action.prev[0] == 'any') {
          return 'any'
        }
      }

      return null
    },

    isAllowedAction(action) {
      var next = this.getNextStatus(action)
      return next != null
    },

    getType(action) {
      switch (action.action) {
        case 'delete':
          return 'danger'
        case 'print':
          return ''
        default:
          return 'primary'
      }
    },

    confirm(action) {
      if (action.withoutConfirmation) {
        this.exec(action)
      } else {
        this.$swal({
          title: this.translate('Are you sure?'),
          text: action.confirmationMessage,
          icon: 'warning',
          showCancelButton: true,
        }).then((result) => {
          if (result && result.isConfirmed) {
            this.exec(action)
          }
        })
      }
    },

    exec(action) {
      var link = this.getActionLinkFormula(action)
      if (link) {
        this.$router.push(link)
      } else {
        this.$emit('onAction', action, this.entity)
      }
    },

    getActionLinkFormula(action) {
      if (action.linkFormula) {
        try {
          const item = this.entity
          var result = eval(action.linkFormula)
          return result
        } catch (e) {
          console.error('ERROR: evaluateFormula:')
          console.error(e.message)
        }
      }

      return null
    },
  },
}
</script>

<style scoped></style>
