// http://vuex.vuejs.org/en/getters.html
import { isEmpty } from 'lodash'

// if has token, we assume that user is logged in our system
export const isLogged = ({ token }) => !isEmpty(token)

// get current user data
export const currentUser = ({ user }) => user

export const currentToken = ({ token }) => token

export const isShopFloorWorker = ({ user }) => {
  return user && !!user.isShopFloorWorker
}

export const globalMetadata = ({ user }) => {
  return user ? user.metadata : null
}

export const mainMenu = ({ user }) => {
  return user && user.mainMenu && user.mainMenu.length > 0
    ? user.mainMenu
    : []
}
