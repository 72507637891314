<template>
  <div class="row">
    <div class="col-md-12 app-title">
      <h1>{{ title }}</h1>

      <div class="app-title-buttons">
        <cc-custom-action-button
          v-for="(actn, idx) in getObjActions()"
          :ref="actn.name"
          :action="actn"
          :entity="entity"
          :key="idx"
          :beforeCustomAction="beforeCustomAction"
          :afterCustomAction="afterCustomAction"
        />

        <cc-actions
          :allowedActions="allowedActions"
          :entity="entity"
          @onAction="onAction"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

/* eslint-disable */

export default {
  name: 'CcTitle',

  props: {
    title: '',
    objectActions: null,
    allowedActions: null,
    entity: {},
    beforeCustomAction: Function,
    afterCustomAction: Function,
  },

  methods: {
    execAction(name) {
      if (this.$refs[name]) {
        this.$refs[name][0].showCustomActionDialog()
      } else {
        console.error(`Unable to find custom action with name '${name}'.`)
      }
    },

    getObjActions() {
      return this.objectActions ? this.objectActions : []
    },

    onAction(action, entity) {
      this.$emit('onAllowedAction', action, entity)
    },
  },
}
</script>

<style scoped></style>
