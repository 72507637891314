import { isArray } from 'lodash'
import Swal from 'sweetalert2';

export default (http, store, router) => {
  // https://github.com/axios/axios#interceptors
  http.interceptors.response.use(
    (response) => response,
    /**
     * This is a central point to handle all
     * error messages generated by HTTP
     * requests
     */
    (error) => {
      const { response } = error

      if(!response) {
        // console.log(error.toString())
        store.dispatch('setFetching', { fetching: false })
        return Promise.reject(error)
      }
      /**
       * If token is either expired, not provided or invalid
       * then redirect to login. On server side the error
       * messages can be changed on app/Providers/EventServiceProvider.php
       */
      if ([401 /*, 400*/ , 403].indexOf(response.status) > -1) {
        router.push({ name: 'auth.signin' }).catch(() => {})
      } 

      Swal.fire({
        icon: 'error',
        title: 'An error occurred',
        text: isArray(response.data)
          ? response.data.messages.join(', ')
          : response.data.error
            ? `(${response.data.error.code}) ${response.data.error.message}`
            : response.data.message || 'An unexpected error occurred',
        confirmButtonText: 'OK'
      });

      store.dispatch('setFetching', { fetching: false })

      return Promise.reject(error)
    }
  )
}