const Reports = () => import('./main.vue')
const WorkcenterBookedTime = () => import('./workcenterbookedtime.vue')
const ResourceAllocationByWorker = () => import('./resourceallocationbyworker.vue')
const ResourceAllocationByWorkcenter = () => import('./resourceallocationbyworkcenter.vue')
const DeliveryPerformance = () => import('./deliveryperformance.vue')
const SupplierDeliveryPerformance = () => import('./supplierdeliveryperformance.vue')
const ContractorDeliveryPerformance = () => import('./contractordeliveryperformance.vue')
const OrdersExecutionOverview = () => import('./ordersexecutionoverview.vue')
const ProjectProgress = () => import('./projectprogress.vue')


const meta = {
  requiresAuth: true,
}

export default [
  {
    name: 'reports',
    path: '/reports',
    component: Reports,
    meta,
    children: [
      {
        name: 'reports.workcenterbookedtime',
        path: '/reports/workcenterbookedtime',
        component: WorkcenterBookedTime,
        meta,
      },
      {
        name: 'reports.resourceallocationbyworker',
        path: '/reports/resourceallocationbyworker',
        component: ResourceAllocationByWorker,
        meta,
      },
      {
        name: 'reports.resourceallocationbyworkcenter',
        path: '/reports/resourceallocationbyworkcenter',
        component: ResourceAllocationByWorkcenter,
        meta,
      },
      {
        name: 'reports.deliveryperformance',
        path: '/reports/deliveryperformance',
        component: DeliveryPerformance,
        meta,
      },
      {
        name: 'reports.supplierdeliveryperformance',
        path: '/reports/supplierdeliveryperformance',
        component: SupplierDeliveryPerformance,
        meta,
      },
      {
        name: 'reports.contractordeliveryperformance',
        path: '/reports/contractordeliveryperformance',
        component: ContractorDeliveryPerformance,
        meta,
      },
      {
        name: 'reports.ordersexecutionoverview',
        path: '/reports/ordersexecutionoverview',
        component: OrdersExecutionOverview,
        meta,
      },
      {
        name: 'reports.projectprogress',
        path: '/reports/projectprogress',
        component: ProjectProgress,
        meta,
      },
    ],
  },
      {
        name: 'reports.workcenterbookedtime',
        path: '/reports/workcenterbookedtime',
        component: WorkcenterBookedTime,
        meta,
      },
      {
        name: 'reports.resourceallocationbyworker',
        path: '/reports/resourceallocationbyworker',
        component: ResourceAllocationByWorker,
        meta,
      },
      {
        name: 'reports.resourceallocationbyworkcenter',
        path: '/reports/resourceallocationbyworkcenter',
        component: ResourceAllocationByWorkcenter,
        meta,
      },
      {
        name: 'reports.deliveryperformance',
        path: '/reports/deliveryperformance',
        component: DeliveryPerformance,
        meta,
      },
      {
        name: 'reports.supplierdeliveryperformance',
        path: '/reports/supplierdeliveryperformance',
        component: SupplierDeliveryPerformance,
        meta,
      },
      {
        name: 'reports.contractordeliveryperformance',
        path: '/reports/contractordeliveryperformance',
        component: ContractorDeliveryPerformance,
        meta,
      },
      {
        name: 'reports.ordersexecutionoverview',
        path: '/reports/ordersexecutionoverview',
        component: OrdersExecutionOverview,
        meta,
      },
      {
        name: 'reports.projectprogress',
        path: '/reports/projectprogress',
        component: ProjectProgress,
        meta,
      },
      
]
