const Alerts = () => import('./main.vue')

const meta = {
  requiresAuth: true,
}

export default [
  {
    name: 'alerts.index',
    path: '/alerts',
    component: Alerts,
    meta,
  },
]
