const ExternalCooperations = () => import('./main.vue')
const Form = () => import('./form.vue')


const meta = {
  requiresAuth: true,
}

export default [
  {
    name: 'externalcooperations.index',
    path: '/externalcooperations',
    component: ExternalCooperations,
    meta,
    children: [
      {
        name: 'externalcooperations.new',
        path: 'create',
        component: Form,
        meta,
      },
      {
        name: 'externalcooperations.edit',
        path: ':id',
        component: Form,
        meta,
      },
    ],
  },
  {
        name: 'externalcooperations.new',
        path: '/externalcooperations/create',
        component: Form,
        meta,
      },
      {
        name: 'externalcooperations.edit',
        path: '/externalcooperations/:id',
        component: Form,
        meta,
      }
]
