const Version = () => import('./main.vue')

const meta = {
  requiresAuth: false,
}

export default [
  {
    name: 'version.index',
    path: '/version',
    component: Version,
    meta,
  },
]
