<template>
    
    <div class="main">
        <el-carousel trigger="click" height="660px" :interval="10000" @change="onCarouselChange">
            <el-carousel-item  height="660px">
                <cc-orders-execution-overview ></cc-orders-execution-overview>
            </el-carousel-item>
          <el-carousel-item v-for="(order, idx) in orders" :key="idx" >
            <div>
                <h1><b>{{ order.id }}</b></h1>

                <div class="main actions-progress" >
                    <div class="row actions-progress-header">
                                <div class=" col-sm-3 col-md-3 col-lg-3">
                                </div>
                                <div class=" col-sm-3 col-md-3 col-lg-3">
                                        {{ translate(`Duration (${toShortString(globalMetadata.durationUnits)})`)  }}
                                </div>

                                <div class=" col-sm-3 col-md-3 col-lg-3">
                                        {{ translate(`Worked (${toShortString(globalMetadata.durationUnits)})`) }}
                                </div>

                                <div class=" col-sm-3 col-md-3 col-lg-3">
                                        {{ translate(`Remaining (${toShortString(globalMetadata.durationUnits)})`) }}
                                </div>
              
                        </div>

                    <div v-for="(act, actionIdx) in getCurrentOrderActions" :key="actionIdx" >
                        <div class="row">
                            <div class=" col-sm-3 col-md-3 col-lg-3">
                                    {{ act.actionType }}
                            </div>
                            <div class=" col-sm-3 col-md-3 col-lg-3">
                                    {{ toDurationInUserUnits(act.durationInMinutes) }}
                            </div>

                            <div class=" col-sm-3 col-md-3 col-lg-3">
                                    {{ toDurationInUserUnits( act.workedMinutes) }}
                            </div>

                            <div class=" col-sm-3 col-md-3 col-lg-3" 
                                :class="act.workedMinutes > act.durationInMinutes ? 'warning' : ''">
                                    {{ toDurationInUserUnits(act.durationInMinutes - act.workedMinutes) }}
                            </div>
                    </div>
                  </div>

                  <div  class="row actions-progress-footer"  >
                                <div class="row">
                                    <div class=" col-sm-3 col-md-3 col-lg-3">

                                    </div>
                                    <div class=" col-sm-3 col-md-3 col-lg-3">
                                            {{ toDurationInUserUnits(totalDurationInMinutes) }}
                                    </div>

                                    <div class=" col-sm-3 col-md-3 col-lg-3">
                                            {{ toDurationInUserUnits(totalWorkedMinutes) }}
                                    </div>

                                    <div class=" col-sm-3 col-md-3 col-lg-3" 
                                        :class="totalRemainingMinutes < 0 ? 'warning' : ''">
                                            {{ toDurationInUserUnits(totalRemainingMinutes) }}
                                    </div>
                            </div>
              </div>              
              </div>

            </div>


          </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
    name: 'CcOrderProgressesCarousel',

    components: {
    },

    props: {
    },

    data() {
        return {
            orders: [],
            currentIdx: 0,
        }
    },

    created() {
        this.timer = setTimeout(this.fetch, 1000);
    },

    mounted() {
    },

    beforeUnmount() {
        if (this.timer)
            clearTimeout(this.timer);
    },

    watch: {
    },

    computed: {
        ...mapGetters(['globalMetadata']),
        
        getCurrentOrderActions() { 
            if (this.orders && this.orders.length > this.currentIdx && this.currentIdx >= 0) {
                return this.orders[this.currentIdx].actions || []
            }
            else { 
                return [];
            }
        },

        totalDurationInMinutes() { 
            var result = 0
            this.getCurrentOrderActions.forEach(n => {
                result += n.durationInMinutes
            })
            return result
        },

        totalWorkedMinutes() {
            var result = 0
            this.getCurrentOrderActions.forEach(n => {
                result += n.workedMinutes
            })
            return result
        },

        totalRemainingMinutes() {
            return this.totalDurationInMinutes - this.totalWorkedMinutes
        },
    },

    methods: {
        ...mapActions(['setFetching']),

        fetch() {
            const uri = 'v1/orders/progressstatistics'

            this.setFetching({ fetching: true })

            this.$http.get(uri).then((res) => {

                this.orders = res.data.orders

                this.setFetching({ fetching: false })

                   // re-fetch on 10 minutes
                this.timer = null;
                this.timer = setTimeout(this.fetch, 10 * 60 * 1000 );
            })
        },

        onCarouselChange(newIdx, oldIdx) { 
            this.currentIdx = newIdx - 1    // because of the first is the overview chart
        }
    },
}
</script>

<style scoped>

.actions-progress {
    margin-top: 32px;
    font-size: x-large;
}

.actions-progress-header {
    margin-bottom: 16px;
    background-color:var(--table-header-background-color);
    font-weight: bold;
}

.actions-progress-footer{
    margin-top: 16px;
    background-color:var(--table-header-background-color);
    font-weight: bold;
}

.warning {
    color: var(--status-warning-background-color)
}

</style>
