<template>
  <div >
    <cc-barchart v-if="hasRows"
      :metadata="metadata"
      :rows="rows"
       />

      <div v-if="!hasRows">
        <h3>{{ translate('No orders in manufacturing or orders matching the filter.') }}</h3>    
      </div>
  </div>
  
  
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'CcOrdersExecutionOverview',

  methods: {},

  props: {
    //reportDataUrl: null,
    //baseroute: null,
    //chartHeight: null,
  },

  data() {
    return {
      metadata: {},
      rows: [],
    }
  },

  mounted() {
    this.fetch()
  },

  computed: {
    ...mapGetters(['globalMetadata']),
    ...mapState({
      fetching: (state) => state.fetching,
    }),

    hasRows() {
      return this.rows && this.rows.length > 0
    }
  },

  methods: {
    ...mapActions(['setFetching']),

    fetch() {
      const uri = 'v1/reporting/ordersexecutionoverview'

      this.setFetching({ fetching: true })

      this.$http.get(uri).then((res) => {
        this.metadata = res.data.metadata
        this.rows = res.data.rows

        this.setFetching({ fetching: false })
      })
    },
  },
}
</script>
