import * as TYPES from '../../store/types'

const state = {
  currentWorker: {},
  currentWorkerAssignableActionIds: [],
  allWorkers: [],
  forceUsingBarcodeScanner: false,
  todayWorkedHours: 0,
  showOrderProgressesCarousel: false
}

/* eslint-disable no-param-reassign */
const mutations = {
  [TYPES.SHOPFLOORCONTROL_SET_DATA](st, obj) {
    if (obj.currentWorker) {
      st.currentWorker = obj.currentWorker
    } else {
      st.currentWorker = {}
    }

    if (obj.currentWorkerAssignableActionIds) {
      st.currentWorkerAssignableActionIds = obj.currentWorkerAssignableActionIds
    } else {
      st.currentWorkerAssignableActionIds = {}
    }

    if (obj.allWorkers) {
      st.allWorkers = obj.allWorkers
    } else {
      st.allWorkers = []
    }

    st.forceUsingBarcodeScanner = obj.forceUsingBarcodeScanner
    st.todayWorkedHours = obj.todayWorkedHours
    st.showOrderProgressesCarousel = obj.showOrderProgressesCarousel
  },
}

const actions = {
  shopFloorControlSetData({ commit }, obj) {
    commit(TYPES.SHOPFLOORCONTROL_SET_DATA, obj)
  },
}

const module = {
  state,
  mutations,
  actions,
}

export default { module }
