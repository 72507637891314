const Providers = () => import('./main.vue')
const Form = () => import('./form.vue')

const meta = {
  requiresAuth: true,
}

export default [
  {
    name: 'providers.index',
    path: '/providers',
    component: Providers,
    meta,
    children: [
      {
        name: 'providers.new',
        path: 'create',
        component: Form,
        meta,
      },
      {
        name: 'providers.edit',
        path: ':id',
        component: Form,
        meta,
      },
    ],
  },
  {
        name: 'providers.new',
        path: '/providers/create',
        component: Form,
        meta,
      },
      {
        name: 'providers.edit',
        path: '/providers/:id',
        component: Form,
        meta,
      }
]
