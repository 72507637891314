import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'

export default {
  install: function(app, connection) {
    if (!connection)
      throw new Error('[SignalR] Missing websockets location URL.')

    let socket = null
    let lastToken = null
    let currentBindings = []
    let onEventCallback = null

    const initializeSocket = () => {
      socket = new HubConnectionBuilder()
        .withUrl(`${connection}?token=${lastToken}`)
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: () => 1000,
        })
        .configureLogging(LogLevel.Warning)
        .build();

      socket.on("msg", (binding, payload)=> {

          if(onEventCallback)
            onEventCallback(binding,payload)
      });

      socket.onclose = () => {
        console.log('SignalR connection closed. Reinitializing...');
        initializeSocket();
      };

      socket.onreconnected((connectionId) => {
        console.log('SignalR re-connected.', currentBindings);


        socket.on("msg", (binding, payload)=> {

          
            if(onEventCallback)
              onEventCallback(binding,payload)
        });

        setBindings(currentBindings);
      });
      
      socket.start()
        .then(() => {
          setBindings(currentBindings);
          console.log('SignalR connection established');
        })
        .catch((error) => {
          console.error('Error starting SignalR connection:', error);
        });
    };

    const authenticate = async (token) => {
      if(lastToken == token)
        return

      lastToken = token

      if(socket) {
        socket.stop()
        socket = null
      }

      if(!token) 
        return

      initializeSocket()

    };

    /*
    const onEvent = (eventName, callback) => {
      socket.on(eventName, (data) => {
        console.log('onEvent')
        callback(data);
      });
    };*/

    const setEventCallback = (callBack) => {
      onEventCallback = callBack
    }

    const setBindings = (bindings) => {
      currentBindings = bindings
      if(socket && socket._connectionState == "Connected")
        socket.invoke("SetBindings", bindings)
    };

    app.config.globalProperties.$socket = {
      authenticate ,
      setBindings,
      setEventCallback,
    };
  },
};