const Settings = () => import('./main.vue')
const AccountForm = () => import('./accountform.vue')

const meta = {
  requiresAuth: true,
}

export default [
  {
    name: 'settings.index',
    path: '/settings',
    component: Settings,
    meta,
    children: [
      {
        name: 'settings.account',
        path: '/settings/account',
        component: AccountForm,
        meta,
      },
    ],
  },
  {
        name: 'settings.account',
        path: '/settings/account',
        component: AccountForm,
        meta,
      }
]
