<template>
  <div class="main">
    <div class="row">
      <div class="col-md-6">
        <h1>{{ translate(metadata.title) }}</h1>
      </div>

      <div class="col-md-3" style="margin-top: 20px">
        <cc-search @searchFilterChanged="filterTable" :metadata="{}" />
      </div>
      <div class="col-md-3" style="margin-top: 20px">
        <el-select
          v-model="selectedFilteringPeriod"
          placeholder="select period"
          @change="selectedOptionsChanged"
        >
          <el-option
            v-for="item in getFilterOptions"
            :key="item.id"
            :label="item.text"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
    </div>

    <div class="group">
      <div class="left">
        <div
          v-for="(item, itemIdx) in getSummaryRows"
          :key="itemIdx"
          class="summary-row"
        >
          <h2>
            {{ item.name }}: <b>{{ item.value }}</b>
          </h2>
        </div>
        <div v-if="hasAvgReport">
          <cc-barchart
            :metadata="getAvgReportMetadata"
            :rows="filteredAvgRows"
            :chartHeight="400"
          />
        </div>
        <div v-if="hasAvgByPeriodReport">
          <cc-linechart
            :hideFilters="true"
            :metadata="getAvgByPeriodReportMetadata"
            :rows="getAvgByPeriodRows"
            :chartHeight="400"
          />
        </div>
      </div>

      <div class="right">
        <cc-var-list
          v-loading.body="fetching"
          :metadata="reportMetadata"
          :items="filteredReportItems"
          :baseroute="baseroute"
          :hideActions="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import { json } from 'express'
import { mapActions, mapGetters, mapState } from 'vuex'
//import CcBarChart  from './barchart.vue'
//import CcLineChart  from './linechart.vue'

export default {
  name: 'CcDdp',

  components: {
    //CcBarChart,
    //CcLineChart,
  },

  props: {
    reportDataUrl: null,
    baseroute: null,
  },

  data() {
    return {
      metadata: {},
      rows: [],
      avgByPeriodRows: [],
      summaryRows: [],
      filter: null,
      selectedFilteringPeriod: '1Y',
    }
  },

  mounted() {
    this.fetch()
  },

  watch: {
    $route: 'fetch',

    reportDataUrl: 'fetch',
  },

  computed: {
    ...mapGetters(['globalMetadata']),
    ...mapState({
      fetching: (state) => state.fetching,
    }),

    getFilterOptions() {
      return this.metadata &&
        this.metadata.filters &&
        this.metadata.filters.options
        ? this.metadata.filters.options
        : []
    },

    getSummaryRows() {
      var result = []
      if (this.summaryRows) {
        for (const property in this.summaryRows) {
          result.push({ name: property, value: this.summaryRows[property] })
        }
      }

      return result
    },

    reportItems() {
      return this.rows || []
    },

    filteredReportItems() {
      const minSearchText = 3

      var result = []

      if (
        this.reportItems &&
        this.filter &&
        this.filter.searchText &&
        this.filter.searchText.length >= minSearchText
      ) {
        var searchText = this.filter.searchText.toLowerCase()
        this.reportItems.forEach((a) => {
          if (this.matchesFilter(a, searchText)) {
            result.push(a)
          }
        })
      } else if (this.reportItems) {
        this.reportItems.forEach((a) => result.push(a))
      }

      return result
    },

    reportMetadata() {
      return this.metadata && this.metadata.report ? this.metadata.report : {}
    },

    hasAvgReport() {
      return (
        this.metadata &&
        this.metadata.avgReport &&
        this.metadata.avgReport.columns
      )
    },

    getAvgReportMetadata() {
      return this.hasAvgReport ? this.metadata.avgReport : []
    },

    getAvgRows() {
      return this.hasAvgReport ? this.avgRows : []
    },

    filteredAvgRows() {
      const minSearchText = 3

      var result = []

      if (
        this.getAvgRows &&
        this.filter &&
        this.filter.searchText &&
        this.filter.searchText.length >= minSearchText
      ) {
        var searchText = this.filter.searchText.toLowerCase()
        this.getAvgRows.forEach((a) => {
          if (this.matchesFilter(a, searchText)) {
            result.push(a)
          }
        })
      } else if (this.getAvgRows) {
        this.getAvgRows.forEach((a) => result.push(a))
      }

      return result
    },

    hasAvgByPeriodReport() {
      return (
        this.metadata &&
        this.metadata.avgByPeriodReport &&
        this.metadata.avgByPeriodReport.columns
      )
    },

    getAvgByPeriodReportMetadata() {
      return this.hasAvgByPeriodReport ? this.metadata.avgByPeriodReport : []
    },

    getAvgByPeriodRows() {
      return this.hasAvgByPeriodReport ? this.avgByPeriodRows : []
    },
  },

  methods: {
    ...mapActions(['setFetching']),

    fetch() {
      var uri = this.reportDataUrl
      if (!uri) {
        return
      }

      uri += `?filteringPeriod=${this.selectedFilteringPeriod}`

      this.setFetching({ fetching: true })

      this.$http.get(uri).then((res) => {
        this.metadata = res.data.metadata
        this.rows = res.data.rows
        this.avgRows = res.data.avgRows
        this.avgByPeriodRows = res.data.avgByPeriodRows
        this.summaryRows = res.data.summaryRows

        this.setFetching({ fetching: false })
      })
    },

    getValue(col, r) {
      var val = r[col.id]
      if (col.type == 'date') {
        return new Date(val)
      }
      return val
    },

    selectedOptionsChanged(val) {
      this.selectedFilteringPeriod = val
      this.fetch()
    },

    filterTable(searchOptions) {
      this.filter = searchOptions
    },

    matchesFilter(item, searchText) {
      if (item) {
        if (this.anyValueMatches([item.name, item.id], searchText)) {
          return true
        }
      }

      return false
    },

    anyValueMatches(values, searchText) {
      for (var i = 0; i < values.length; i++) {
        if (values[i]) {
          if (`${values[i]}`.toLowerCase().indexOf(searchText) >= 0) {
            return true
          }
        }
      }
      return false
    },
  },
}
</script>

<style scoped>
.summary-row {
  text-align: center;
}
</style>
