const Orders = () => import('./main.vue')
const Form = () => import('./form.vue')

const meta = {
  requiresAuth: true,
}

export default [
  {
    name: 'orders.index',
    path: '/orders',
    component: Orders,
    meta,
    children: [
      {
        name: 'orders.new',
        path: 'create',
        component: Form,
        meta,
      },
      {
        name: 'orders.edit',
        path: ':id',
        component: Form,
        meta,
      },
    ],
  },
  {
        name: 'orders.new',
        path: '/orders/create',
        component: Form,
        meta,
      },
  {
    name: 'orders.edit',
    path: '/orders/:id',
    component: Form,
    meta,
  }
]
