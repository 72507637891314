const Components = () => import('./main.vue')
const Form = () => import('./form.vue')

const meta = {
  requiresAuth: true,
}

export default [
  {
    name: 'components.index',
    path: '/components',
    component: Components,
    meta,
    children: [
      {
        name: 'components.new',
        path: 'create',
        component: Form,
        meta,
      },
      {
        name: 'components.edit',
        path: ':id',
        component: Form,
        meta,
      },
    ],
  },
  {
        name: 'components.new',
        path: '/components/create',
        component: Form,
        meta,
  },
  {
    name: 'components.edit',
    path: '/components/:id',
    component: Form,
    meta,

  }


]
