// https://vuex.vuejs.org/en/state.html

export default {
  messages: {
    success: '',
    error: [],
    warning: '',
    validation: [],
  },

  fetching: false,

  totalsMinimized: true,
}
