<template>
  <div >
    <div class="current-file">
      <a
        href="javascript:void(0);"
        v-on:click="openFileDocumentInternal(file.id)"
        >{{ this.file.name }}</a
      >
      <el-button
        v-if="shouldShowDeleteFileContents()"
        @click="deleteFileContents()"
        type="danger"
        :icon="Delete"
        size="small"
      ></el-button>
    </div>

    <div v-if="!readonly" >
      <form
        enctype="multipart/form-data"
        novalidate
        v-if="isInitial || isSaving"
        style="line-height: 28px"
      >
        <div class="dropbox-small el-button">
          <input
            type="file"
            :name="uploadFieldName"
            :disabled="isSaving"
            :multiple="isMultiple"
            @change="
              filesChange($event.target.name, $event.target.files);
              this.fileCount = $event.target.files.length
            "
            accept="image/*, text/html, application/pdf"
            class="input-file-small"
            :title="translate('Add new file - click here')"
          />

          <el-icon class="upload-img" style="font-size: 24px; position: inherit; float: none"><Upload/></el-icon>
          
          <!--<span>...</span>-->
        </div>
      </form>
      <!--SUCCESS-->
      <!--<div v-if="isSuccess">
          <h2>Uploaded  successfully.</h2>
        </div> -->
      <!--FAILED-->
      <div v-if="isFailed">
        <h2>Uploaded failed.</h2>
        <p>
          <a href="javascript:void(0)" @click="reset()">Try again</a>
        </p>
        <pre>{{ uploadError }}</pre>
      </div>
    </div>
  </div>
</template>

<script>

// import { mapActions, mapState } from 'vuex'
import { Delete  } from '@element-plus/icons-vue'
import { markRaw } from 'vue'
const STATUS_INITIAL = 0
const STATUS_SAVING = 1
const STATUS_SUCCESS = 2
const STATUS_FAILED = 3

export default {
  data() {
    return {
      uploadError: null,
      currentStatus: null,
      uploadFieldName: '',
      currentTemporaryFileId: -1,
      Delete: markRaw(Delete),
    }
  },
  name: 'CcFileCtrl',
  props: {
    readonly: false,
    file: null,
    isMultiple: false,
  },
  computed: {
    isInitial() {
      return (
        this.currentStatus === STATUS_INITIAL ||
        this.currentStatus === STATUS_SUCCESS
      )
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED
    },
  },
  methods: {
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL
      this.uploadError = null
      this.currentTemporaryFileId = -1
    },

    filesChange(fieldName, fileList) {
      // handle file changes
      if (fileList.length > 0) {
        this.appendNotSubmitedFile(fileList[0])
      }
    },

    deleteFileContents() {
      this.file._notSubmitedContents = null
      this.file.name = null
    },

    openFileDocumentInternal() {
      if (this.file._notSubmitedContents) {
        this.openNotSubmitedFileDocument()
      } else if (this.file.id > 0) {
        this.openFileDocument(this.file.id, true)
      }
    },

    appendNotSubmitedFile(newFile) {
      var currentFile = this.file
      var reader = new FileReader()
      reader.onload = function () {
        currentFile.name = newFile.name
        currentFile._notSubmitedContents = reader.result
      }
      reader.onerror = function (error) {
        console.error('Error: ', error)
      }

      reader.readAsDataURL(newFile)
    },

    openNotSubmitedFileDocument() {
      if (
        this.file._notSubmitedContents &&
        this.file._notSubmitedContents.startsWith('data:text/html')
      ) {
        var newWindow = window.open()
        newWindow.document.body.innerHTML = this.decodeBase64(
          this.file._notSubmitedContents
        )
      } else {
        /* Old way doesn't work on Chrome

    const a = document.createElement("a")
    a.href = this.file._notSubmitedContents
    a.openFile = this.file.name
    // a.download = response.data.name; // if we need to download it!!!
    a.target="_blank"
    document.body.appendChild(a)
    a.click()*/

        // base64:
        //    data:text/plain;base64,VEVTVCBDVVNUT0
        const base64str = ';base64,'
        var pos = this.file._notSubmitedContents.indexOf(base64str)
        const contentType = this.file._notSubmitedContents.substring(5, pos)
        //console.log('contentType: ' + contentType)
        var contents = this.file._notSubmitedContents.substring(
          pos + base64str.length
        )
        //console.log('contents: ' + contents)

        this.openFileDocumentNew(contentType, this.file.name, contents)
      }
    },

    getUploadinText() {
      return `Uploading ${this.fileCount} files...`
    },

    shouldShowDeleteFileContents() {
      return !this.readonly && this.file.name
    },
  },
  mounted() {
    this.reset()
  },
}
</script>

<style lang="scss">
div.current-file {
  margin-bottom: 8px;
}

p.el-icon-upload {
  color: var(--table-master-row-background-color);
}

.dropbox-small {
  /*
    outline: 1px dashed grey; // the dash box
    outline-offset: -10px;
    */
  min-height: 40px;
  cursor: pointer;
  // margin-left: -18px;
  position: relative;

  display: block;
  /*	border-style:solid;
	border-width:3px 4px 4px 3px;*/
  // width:9em;
  width: 100%;
  //margin-top: 10px;
  padding: 0px 0px;
  height: 2em;
  /*
	line-height:2;
	text-align:center;
	text-decoration:none;
  */
  font-weight: 900;
}

.dropbox-small:hover {
  /*  will get styles from .el-button

	  border-width:4px 3px 3px 4px;
	  color:#000;*/
}
.input-file-small {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
  border-radius: 4px;
  left: 0;
  //margin-top: 10px;
  
}

.dropbox p {
  font-size: 1.2em;
  text-align: left;
  padding: 8px 28px;
  
}
.upload-img {
  position: absolute;
  right: 0;
  pointer-events: none;
  margin-right: 16px;
  margin-top:8px;
  width: 140px;
  
  
}
</style>
