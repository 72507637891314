<template>
  <div>
    <div v-if="action.processorName == 'PdfAndEmail'">
      <el-button
        type="primary"
        class="header-button"
        :icon="action.showIcon"
        @click="showDialogPdfAndEmail"
        >{{ translate(action.displayText) }}</el-button
      >

      <el-dialog
        :title="getPdfAndEmailDialogTitle(action)"
        v-model="dialogFormVisible_PdfAndEmail"
      >
        <el-form-item label="To" :label-width="formLabelWidth">
          <el-input v-model="__email"></el-input>
        </el-form-item>
        <el-form-item label="Subject" :label-width="formLabelWidth">
          <el-input v-model="action.settings.emailTemplate.subject"></el-input>
        </el-form-item>
        <el-form-item :label="translate('Text')" :label-width="formLabelWidth">
          <el-input
            type="textarea"
            v-model="action.settings.emailTemplate.body"
          ></el-input>
        </el-form-item>

        <el-divider></el-divider>

        <div v-for="(item, itemIdx) in getCustomFields" :key="itemIdx">
          <el-form-item :label="item.displayText" :title="item.displayText">
            <cc-var-control
              :metadata="{ id: 'value', type: 'input' }"
              :entity="item"
              property="value"
              :rootEntity="entity"
              :boldLabels="true"
            ></cc-var-control>
          </el-form-item>
        </div>

        <!-- <el-divider></el-divider> -->
        <template v-slot:footer>

        <span class="dialog-footer">
          <el-button
            href="javascript:void(0);"
            v-on:click="
              openFileDocument(buildFileDocumentId(action.name, entity))
            "
            :icon="Document"
            style="float: left; margin-left: 120px"
            >&nbsp;&nbsp;{{ action.displayText }}</el-button
          >

          <el-button @click="dialogFormVisible_PdfAndEmail = false"
            >Cancel</el-button
          >
          <el-button type="primary" @click="sendEmail">Изпрати</el-button>
        </span>
        </template>
      </el-dialog>

    </div>
    <div v-else-if=" action.processorName == 'HtmlGenerator' || action.processorName == 'PdfGenerator' || action.processorName == 'XlsGenerator'">
      <div>
        <el-button
          type="primary"
          class="header-button"
          :icon="action.showIcon"
          href="javascript:void(0);"
          v-on:click="
            openFileDocument(buildFileDocumentId(action.name, entity))
          "
          >{{ translate(action.displayText) }}</el-button
        >
      </div>
    </div>
    <div v-else-if="action.processorName == 'PasswordReset'">
      <el-button
        type="primary"
        class="header-button"
        :icon="action.showIcon"
        @click="resetUserPassword"
        >{{ action.displayText }}</el-button
      >
    </div>
    <div v-else-if="action.processorName == 'Import'">
      
        <el-button
        
          type="primary"
          class="header-button"
          :icon="action.showIcon"
          href="javascript:void(0);"
          @click="showDialogImport"
          >{{ translate( action.displayText )}}</el-button>

        <el-dialog
            :title="action.displayText"
            v-model="dialogFormVisible_Import">

          
          <!--
            <el-form-item label="ID" :label-width="formLabelWidth">
              <el-input v-model="todo.other-value"></el-input>
            </el-form-item>

            <el-divider></el-divider>-->

            <div v-for="(item, itemIdx) in getCustomFields" :key="itemIdx">
              <el-form-item :label="item.displayText" :title="item.displayText" >
                <cc-var-control
                  :metadata="{ id: 'value', type: 'input' }"
                  :entity="item"
                  property="value"
                  :rootEntity="entity"
                  :boldLabels="true"
                ></cc-var-control>
              </el-form-item>
            </div>

            <!-- <el-divider></el-divider> -->
            <template v-slot:footer>

            <span class="dialog-footer">
              <el-button type ="primary" @click="importObject">{{ action.displayText }}</el-button>


              <el-button @click="dialogFormVisible_Import = false">Cancel</el-button>
            </span>
            </template>


          </el-dialog>
      </div>
      <div v-else-if="action.processorName == 'UploadDocuments'">
         <el-upload
            :file-list="uploadDocuments_fileList"
            :action="uploadUrl"
            :headers="uploadHeaders"
            multiple
            :auto-upload="true"
            :on-change="onUploadDocumentsChange"
            :on-success="onUploadDocumentsSuccess"
            :on-error="onUploadDocumentsError"
            accept="application/pdf"
          >

          <el-button type="primary"
            class="header-button"
            :icon="action.showIcon"
            href="javascript:void(0);"
            >{{ action.displayText }}</el-button>
            
            
          </el-upload> 
    </div>
    <div v-else-if="action.processorName == 'Other'">
          <el-button type="success"
            class="header-button"
            :icon="action.showIcon"
            href="javascript:void(0);"
            @click="onCustomActionOthers"
            >{{ action.displayText }}</el-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { Document } from '@element-plus/icons-vue'
import { markRaw } from 'vue'

import { ElLoading } from 'element-plus'
import { getToken } from '../../plugins/http'

import { apiUrl } from '../../config'

export default {
  name: 'CcCustomActionButton',

  components: {},

  data() {
    return {
      dialogFormVisible_PdfAndEmail: false,
      dialogFormVisible_Import: false,
      uploadDocuments_fileList: [],
      uploadDocuments_errors: [],
      formLabelWidth: '200px',
      Document: markRaw(Document),
      __email: undefined,
    }
  },

  props: {
    action: {},
    entity: {},
    filter: '',
    beforeCustomAction: Function,
    afterCustomAction: Function,
  },

  computed: {
    getCustomFields() {
      if (this.action && this.action.customFields) {
        this.action.customFields.forEach(n => { 
          if (n.formula) { 
            n.value = this.evaluateFormula(this.entity, n.formula)
          }
        })
        return this.action.customFields
      } else { 
        return []
      }
    },

    uploadUrl() { 
      return `${apiUrl}/v1/upload?uploadType=orderComponentDocuments&entityId=${this.entity.id}`
    },

    uploadHeaders() { 
      return {
        Authorization: getToken()
      }
    }
  },

  watch: {
    'action': function () {
      this.recalcEmail()
    },
    'entity': function () {

      this.recalcEmail()
    },
  },

  methods: {
    recalcEmail() {
      var result = ''
      if (this.entity.customer) {
        result = this.entity.customer.emails[0].value
      } else if (this.entity.email) {
        result = this.entity.email
      }

      this.__email = result
    },

    showDialogPdfAndEmail() {
      if (this.beforeCustomAction) {
        this.beforeCustomAction().then(() => {
          this.dialogFormVisible_PdfAndEmail = true
        })
      } else {
        this.dialogFormVisible_PdfAndEmail = true
      }
    },

    showDialogImport() {
      if (this.beforeCustomAction) {
        this.beforeCustomAction().then(() => {
          this.dialogFormVisible_Import = true
        })
      } else {
        this.dialogFormVisible_Import = true
      }
    },

    showCustomActionDialog() {
      if (this.action.processorName == 'PdfAndEmail') {
        this.dialogFormVisible_PdfAndEmail = true
      } else {
        console.error(
          `Unsupported custom action button: '${this.action.processorName}'`
        )
      }
    },

    sendEmail() {
      var model = {
        emails: this.__email,
        subject: this.action.settings.emailTemplate.subject,
        body: this.action.settings.emailTemplate.body,
      }

      //this.setFetching({ fetching: true })
      this.$http
        .post('v1/customactions', {
          action: this.action,
          data: model,
          customFields: this.action.customFields,
          itemId: this.entity.id,
        })
        .then((res) => {
          this.dialogFormVisible_PdfAndEmail = false

          if (!!res.data.postAction && res.data.postAction.length > 0) {
            this.evaluateFormula(this.entity, res.data.postAction)
          }

          this.$swal('Done', this.translate('Email was sent.'), 'success')
        })
        .catch((error) => {
          this.showApiError(error)
        })
    },

    importObject() { 

      const loadingInstance = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      this.$http
        .post('v1/customactions', {
          action: this.action,
          data: {},
          customFields: this.action.customFields,
          itemId: this.entity.id,
        })
        .then((res) => {
          // this.setFetching({ fetching1: false })
          loadingInstance.close()

          this.dialogFormVisible_Import = false
          if (!!res.data.postAction && res.data.postAction.length > 0) {
            this.evaluateFormula(this.entity, res.data.postAction)
          }

          if (this.afterCustomAction)
            this.afterCustomAction()

          this.$swal('Done', this.translate('Successfully Imported.'), 'success')
        })
        .catch((error) => {
          // this.setFetching({ fetching: false })
          loadingInstance.close()
          this.showApiError(error)
        })
        
    },

    onCustomActionOthers() {
      var confirmMessage = this.action.customFields.find(n=>n.variableName=='confirmMessage').displayText
      const item = this.entity
      this.$swal({
        title: this.translate('Are you sure?'),
        text: this.translate(confirmMessage),
        icon: 'warning',
        showCancelButton: true,
      }).then((result) => {
        if (result && result.isConfirmed) {
          this.$http
            .post('v1/customactions', {
              action: this.action,
              data: {},
              customFields: this.action.customFields,
              itemId: this.entity.id,
            })
            .then((res) => {
              if (this.afterCustomAction)
                this.afterCustomAction()
            })
            .catch((error) => {
              this.showApiError(error)
            })
        }
      })
    },

    evaluateFormula(item, formula) {
      try {
        return eval(formula)
      } catch (e) {
        console.error('ERROR: evaluateFormula:')
        console.error(e.message)
        return undefined
      }
    },

    getItemDisplayName(itm) {
      if (itm.name) {
        return itm.name
      } else if (itm.component && itm.component.name) {
        // custom for order compoent's
        return itm.component.name
      } else {
        return itm.id
      }
    },

    buildFileDocumentId(actionName, item) {
      var result = `action=${actionName}`

      if (this.action && this.action.customFields && this.action.customFields.length) {
        var customFields = ''
        this.action.customFields.forEach((n) => {
          customFields += `${n.variableName}:${n.value};`
        })
        result += `&customFields=${customFields}`
      }

      if (this.filter && this.filter.length) {
        var idx = this.filter.indexOf('?')
        var filter = idx > 0 ? this.filter.substring(idx + 1) : ''
        result += `&${filter}`
      } else if (Array.isArray(item)) {
        var ids = ''
        item.forEach((n) => {
          ids += `${n.id}_`
        })
        result += `&ids=${ids}`
      } else {
        result += `&ids=${item.id}`
      }

      return result
    },

    resetUserPassword() {
      const item = this.entity
      this.$swal({
        title: this.translate('Are you sure?'),
        text: `${this.translate('You want to reset password of user')}: ${
          item.userName
        }.`,
        icon: 'warning',
        showCancelButton: true,
      }).then((result) => {
        if (result && result.isConfirmed) {
          // var origin = encodeURIComponent(window.location.origin)
          this.$http
            .delete(`v1/userpassword/${item.id}`)
            .then((res) => {
              if (res.data.success) {
                this.$swal(
                  'Done',
                  this.translate(
                    'Password was retested successfully. Email with instructions to set new password was sent to the user.'
                  ),
                  'success'
                )
              } else {
                this.$swal(
                  'Error',
                  this.translate(res.data.errorMessage) +
                    '\r\n' +
                    this.translate(
                      'Please reset password manually by sending the following activation link to the user:'
                    ) +
                    '\r\n\r\n' +
                    res.data.activationLink,
                  'error'
                )
              }
            })
            .catch((error) => {
              this.showApiError(error)
            })
        }
      })
    },

    getPdfAndEmailDialogTitle(action) {
      return `${this.translate('Send of')} ${action.displayText}`
    },

    onUploadDocumentsChange() { 
    },

    onUploadDocumentsSuccess(response, _, uploadFiles) { 
      const count = uploadFiles.length + this.uploadDocuments_errors.length
      const successCount = uploadFiles.length
      var message = `${successCount} of ${count} files uploaded.`
      if (this.uploadDocuments_errors.length > 0) { 
        message += '<br/>Messages:<br/>'
        this.uploadDocuments_errors.forEach(err=>message+=err +'\r\n')
      }

      this.uploadDocuments_fileList = []
      this.uploadDocuments_errors = []

      if (this.afterCustomAction)
        this.afterCustomAction()

      this.$swal('Done', message, 'success')
    },

    onUploadDocumentsError(error) {
      this.uploadDocuments_errors.push(error)
    },
  },
}
</script>

<style lang="scss">
.dialog-table-cell {
  padding: 5px;
}


</style>
