import * as TYPES from '../../store/types'

const state = {
  filter: {},
}

/* eslint-disable no-param-reassign */
const mutations = {
  [TYPES.WORKCENTERS_SET_FILTER](st, obj) {
    if (obj.filter) {
      st.filter = obj.filter
    }
  },
}

const actions = {
  workcentersSetFilter({ commit }, obj) {
    commit(TYPES.WORKCENTERS_SET_FILTER, obj)
  },
}

const module = {
  state,
  mutations,
  actions,
}

export default { module }
