import * as TYPES from '../../store/types'

const state = {
  providers: [],
  providers_metadata: {},
  readOnly: false,
}

/* eslint-disable no-param-reassign */
const mutations = {
  [TYPES.PROVIDERS_SET_DATA](st, obj) {
    if (obj.providers) {
      st.providers = obj.providers
    }

    if (obj.metadata) {
      st.providers_metadata = obj.metadata
    }

    st.readOnly = !!obj.readOnly
  },
}

const actions = {
  providersSetData({ commit }, obj) {
    commit(TYPES.PROVIDERS_SET_DATA, obj)
  },
}

const module = {
  state,
  mutations,
  actions,
}

export default { module }
