import * as TYPES from '../../store/types'

const state = {
  orders: [],
  orders_closed: [],
  orders_metadata: {},
  orders_metadata_closed: {},
  readOnly: false,
}

/* eslint-disable no-param-reassign */
const mutations = {
  [TYPES.ORDERS_SET_DATA](st, obj) {
    if (obj.orders) {
      st.orders = obj.orders
    }
    if (obj.orders_closed) {
      st.orders_closed = obj.orders_closed
    }
    if (obj.metadata) {
      st.orders_metadata = obj.metadata
    }
    if (obj.metadata_closed) {
      st.orders_metadata_closed = obj.metadata_closed
    }
    st.readOnly = !!obj.readOnly
  },
}

const actions = {
  ordersSetData({ commit }, obj) {
    commit(TYPES.ORDERS_SET_DATA, obj)
  },
}

const module = {
  state,
  mutations,
  actions,
}

export default { module }
