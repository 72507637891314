export default {
  translate(str, lang) {
    if (lang == 'EN') {
      return str
    }

    if (lang == 'BG') {
      return this.translateBG(str)
    }

    return str
  },

  translateBG(str) {
    switch (str) {
      case 'Password':
        return 'Парола'
      case 'Sign in':
        return 'Вписване'
      case 'Your email':
        return 'Вашият email'
      case 'Your password':
        return 'Вашата парола'
      case 'Login':
        return 'Вписване'
      case 'Free Trial Account':
        return 'Безплатен Тестови Акаунт'
      case 'Create Free Trial Account':
        return 'Създаване на Безплатен Тестови Акаунт'
      case 'Contact':
        return 'Контакт'
      case 'Comming soon':
        return 'Очаквайте скоро'
      case 'For free trial account creation, please contact us at':
        return 'За създаване на безплатен тестови акаунт се с нас на'
      case 'Successfully signed out':
        return 'Успешен изход.'
      case 'Thank you for your interest in a free trial period of Prodexis MRP.':
        return 'Благодарим ви за интереса към безплатен пробен период на Prodexis MRP.'
      case 'Please fill out the form below so we can better understand your needs.':
        return 'Моля, попълнете формуляра по-долу, за да разберем по-добре нуждите ви.'
      case 'Your company':
        return 'Вашата фирма'
      case 'Your name':
        return 'Вашето име'
      case 'Your position':
        return 'Вашата позиция'
      case 'Count of employees':
        return 'Брой работници'
      case 'Additional details':
        return 'Допълнителни детайли'
      case 'Send':
        return 'Изпрати'
      case 'Thank you for requesting a free trial! Your request has been submitted successfully, and we will reach out to you shortly.':
        return 'Благодарим ви за интереса за безплатен пробен период! Вашата заявка е изпратена успешно и ние ще се свържем с вас скоро.'
      case 'Go back to www.prodexis.com':
        return 'Към www.prodexis.com'
      default:
        return str
    }
  },
}
