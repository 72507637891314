<template>
  <div class="main">
    <div v-show="shouldShowFilters" class="row">
      <div class="col-md-6" style="width: 60%">
        <h1>{{ getMetadata.title }}</h1>
      </div>
      <div class="col-md-4">
        <el-select
          v-model="selectedFilteringPeriod"
          placeholder="select period"
          @change="selectedOptionsChanged"
        >
          <el-option
            v-for="item in getFilterOptions"
            :key="item.id"
            :label="item.text"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <!-- https://developers.google.com/chart/interactive/docs/gallery/timeline -->
    <!-- https://github.com/devstark-com/vue-google-charts -->
    <!-- https://developers.google.com/chart/interactive/docs/gallery/ganttchart -->
    <!-- <GChart :settings="{ packages: ['linechart'] }" type="LineChart" :data="chartData" :options="getChartOptions" /> -->
    <GChart type="LineChart" :data="chartData" :options="getChartOptions" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { GChart } from 'vue-google-charts'

/* eslint-disable */
export default {
  name: 'CcLineChart',

  components: {
    GChart,
  },

  props: {
    reportDataUrl: null,
    hideFilters: false,
    metadata: null,
    rows: null,
    chartHeight: null,
  },

  data() {
    return {
      chartOptions: {
        curveType: 'function',
        height: 640,
        // backgroundColor: '#1B1B1B',
        chartArea: {
          backgroundColor: 'transparent',
        },
        hAxis: {
          textStyle: {
            // color: '#ffffff',
          },
          gridlines: {
            color: 'transparent',
          },
        },
        legend: {
          position: 'right',
          maxLines: 3,
          textStyle: {
            // color: '#ffffff',
          },
        },
        vAxis: {
          textStyle: {
            // color: '#ffffff',
          },
          gridlines: {
            color: 'transparent',
          },
        },
      },

      reportMetadata: {},
      reportRows: [],

      selectedFilteringPeriod: '1Y',
    }
  },

  mounted() {
    this.fetch()
  },

  watch: {
    $route: 'fetch',

    reportDataUrl: 'fetch',
  },

  computed: {
    ...mapGetters(['globalMetadata']),

    shouldShowFilters() {
      return !this.hideFilters
    },

    getMetadata() {
      return this.reportDataUrl ? this.reportMetadata : this.metadata
    },

    getRows() {
      return this.reportDataUrl ? this.reportRows : this.rows
    },

    getChartOptions() {
      var result = this.chartOptions

      var backgroundColor =
        document.body.style.getPropertyValue('--background-color')
      var color = document.body.style.getPropertyValue('--main-text-color')

      if (backgroundColor) {
        result.backgroundColor = backgroundColor
      }

      if (color) {
        result.legend.textStyle.color = color
        result.vAxis.textStyle.color = color
        result.hAxis.textStyle.color = color
      }

      if (this.chartHeight && this.chartHeight > 0)
        result.height = this.chartHeight

      if (!this.shouldShowFilters && this.getMetadata.title) {
        result.title = this.getMetadata.title
        result.titleTextStyle = {
          color: color,
          fontSize: 18,
          bold: false,
        }
      }

      return result
    },

    // Array will be automatically processed with visualization.arrayToDataTable function
    chartData() {
      /* example
        return [
          ['Year', 'Sales', 'Expenses'],
          ['2004',  1000,      400],
          ['2005',  1170,      460],
          ['2006',  660,       1120],
          ['2007',  1030,      540]
          ] */

      var metadata = this.getMetadata

      var result = []
      if (!metadata || !metadata.columns) {
        return [['Year', 'A', 'B']]
      }

      var header = []
      metadata.columns.forEach((n) => header.push(n.text))
      result.push(header)

      this.getRows.forEach((r) => {
        var item = []
        metadata.columns.forEach((col) => item.push(this.getValue(col, r)))
        result.push(item)
      })

      return result
    },

    getFilterOptions() {
      var metadata = this.getMetadata
      return metadata && metadata.filters && metadata.filters.options
        ? metadata.filters.options
        : []
    },
  },

  methods: {
    ...mapActions(['setFetching']),

    fetch() {
      var uri = this.reportDataUrl
      if (!uri) {
        return
      }

      uri += `?filteringPeriod=${this.selectedFilteringPeriod}`

      this.setFetching({ fetching: true })

      this.$http.get(uri).then((res) => {
        this.reportMetadata = res.data.metadata
        this.reportRows = res.data.reportRows
        this.setFetching({ fetching: false })
      })
    },

    getValue(col, r) {
      var val = r[col.id]
      if (col.type == 'date') {
        return new Date(val)
      }
      return val
    },

    selectedOptionsChanged(val) {
      this.selectedFilteringPeriod = val
      this.fetch()
    },
  },
}
</script>
