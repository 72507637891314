<template>
  <div>
    <div
      v-for="(itm, idx) in getInputArrayModel"
      :key="idx"
      class="array-row-container"
    >
      <el-link
        v-if="!readOnly && getElementType == 'inputLinks'"
        :icon="View"
        size="small"
        v-on:click="openLink(itm)"
      ></el-link>

      <el-input
        v-if="!readOnly"
        v-model="itm.entity[itm.propName]"
        :type="getElementType"
      ></el-input>

      <span v-if="readOnly" class="row-index">{{
        itm.entity[itm.propName]
      }}</span>

      <el-button
        v-if="!readOnly"
        @click="removeItem(itm)"
        :disabled="idx == 0"
        :icon="Minus"
        size="small"
      ></el-button>
    </div>

    <el-button
      v-if="!readOnly"
      @click="addItem()"
      :type="listButtonType"
      size="small"
      style="margin-top: 8px"
      :icon="Plus"
    ></el-button>
  </div>
</template>

<script>
import { markRaw } from 'vue'
import { View, Plus, Minus } from '@element-plus/icons-vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      View: markRaw(View),
      Plus: markRaw(Plus),
      Minus: markRaw(Minus),
    }
  },
  name: 'CcInputArray',
  components: {},
  props: {
    metadata: null, // {type: 'label', formula: '', options: [], lookupEntityType: 'Customer' }
    entity: {},
    property: '',
    readOnly: false,
    type: undefined,
  },
  computed: {
    ...mapGetters(['globalMetadata']),

    listButtonType() {
      var appSkin = this.globalMetadata ? this.globalMetadata.appSkin : null
      if (appSkin && appSkin.styles['--list-button-type']) {
        return appSkin.styles['--list-button-type']
      }
      return 'default'
    },

    isPlainButton() {
      return this.listButtonType != 'primary'
    },

    getElementType() {
      if (this.type == 'email') {
        return 'email'
      } else if (this.type == 'inputLinks') {
        //console.log('inputLinks')
        return 'inputLinks'
      }

      return ''
    },

    getInputArrayModel() {
      var items = this.getInputArrayItems()
      if (!items) {
        return []
      }

      var result = []

      if (this.metadata && this.metadata.formula) {
        var fields = this.metadata.formula.split(';')
        // todo - possibility to add multiple fields
        var propName = fields[0]

        items.forEach((n) => result.push({ entity: n, propName: propName }))
      } else {
        items.forEach((n) => result.push({ entity: n, propName: 'value' }))
      }

      return result
    },
  },
  watch: {},
  methods: {
    getInputArrayItems() {
      if (!this.entity[this.property]) {
        this.entity[this.property] = []
      }
      if (this.entity[this.property].length == 0) {
        this.entity[this.property].push({})
      }
      return this.entity[this.property]
    },

    addItem() {
      this.getInputArrayItems().push({})
    },

    removeItem(item) {
      var items = this.getInputArrayItems()
      if (items.indexOf(item.entity) > 0) {
        var realIdx = this.entity[this.property].indexOf(item.entity)
        this.entity[this.property].splice(realIdx, 1)
      }
    },

    openLink(item) {
      this.navigateToLink(item.entity['value'])
    },
  },
}
</script>

<style lang="scss">
.array-row-container {
  display: flex;
  margin-left: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.array-row-container button {
  margin-left: 8px;
}

.array-row-container a {
  margin-right: 8px;
}
</style>
